import './App.css';
import {Route , BrowserRouter, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import HowToPlay from './HowToPlay';
import Faqs from './Faq';
import PrivacyPolicy from './PrivacyPolicy';
import FairPlay from './FairPlay';
import TermsAndConditions from './TermsAndConditions';
import ScrollToTop from './ScrollToTop';
import ResponsibleGaming from './ResponsibleGaming';
import PointCalculations from './PointCalculations';
import ApkDownload from './ApkDownload';

function App() {
    
    // const reload = () => window.location.reload();

  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home />} exact />
              <Route path="/content/about-us" element={<About />} />        
              <Route path="/content/how-to-play" element={<HowToPlay />} />        
              <Route path="/content/contact-us" element={<Contact />} />        
              <Route path="/content/Terms-and-Conditions" element={<TermsAndConditions />} />        
              <Route path="/content/faqs" element={<Faqs />} />        
              <Route path="/content/fair_play" element={<FairPlay />} />        
              <Route path="/content/fair-play" element={<FairPlay />} />        
              <Route path="/content/Privacy-Policy" element={<PrivacyPolicy />} />        
              <Route path="/content/Responsible-Gaming" element={<ResponsibleGaming />} />        
              <Route path="/content/point-calculations" element={<PointCalculations />} />        
              <Route path="/content/apk-download" element={<ApkDownload />} />        
          </Routes>
          <ScrollToTop />
      </BrowserRouter>
  )
}

export default App;
