import "./App.css";
import "./utils/styles/content.css";
import phone_logo from "../src/assets/phone_logo.png";
import mail_logo from "../src/assets/mail_logo.png";
import IosBtn from "../src/assets/ios_btn.png";
import PlayStoreBtn from "../src/assets/playstore_btn.png";
import LogoIcon from "../src/assets/Logo.png";
import Footer from "../src/Footer";
import { Link } from "react-router-dom";
import { APP_STORE_URL } from "./utils/constant";
import { Helmet } from "react-helmet";

function ResponsibleGaming() {
  return (
    <div className="background-image mb-5">
      <Helmet>
        <title> Responsible Gaming </title>        
      </Helmet>
      <div className="header p-md-3 p-2 text-end w-100">
        <b>
          <a href="mailto: support@pickmonk.com">
            <span className="text-light p-md-3 p-1">support@pickmonk.com</span>
            <img src={mail_logo} alt="email icon" />
          </a>
        </b>
        <b>
          <a href="tel:+91 79 35106161">
            <span className="text-light p-md-3 p-2 ps-md-5 ps-4">
              +91 79 35106161
            </span>
            <img src={phone_logo} alt="phone call icon" />
          </a>
        </b>
      </div>
      <Link to="/">
        <img className="logo_icon ms-4 mt-3" src={LogoIcon} alt="pickmonk fantasy app logo" />
      </Link>

      <div className="content_container ">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h1 className="content_box_heading">Responsible Gaming</h1>
          </div>
          <div className="col-md-12 col-sm-12 p-3 d-flex align-items-center justify-content-end">
            <div className="row">
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={APP_STORE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="store_icon" src={IosBtn} alt="app store download button" />
                </Link>
              </div>
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={"https://play.google.com/store/apps/details?id=com.pickmonk"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="store_icon" src={PlayStoreBtn} alt="google playstore download button" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div class="trapezoid_right "></div>
          <div className="p-sm-5 p-3   bg-dark">
            <p className="content_box_text">
              <h3>Responsible Gaming Policy</h3>
              <p>
                Pickmonk runs all contests with integrity, fairness, and
                reliability. Pickmonk is committed to responsible gaming and is
                dedicated to an enjoyable and positive gaming experience for all
                of our players. We are committed to ensuring that you enjoy your
                Daily Fantasy Sports (DFS) experience on our App while remaining
                fully aware of the social and financial effects associated with
                excessive involvement in Daily Fantasy Sports games. We offer
                and promote our skill games as an enjoyable entertainment
                activity and believe that fantasy sports can remain this way
                only if you play responsibly and stay in control. It involves an
                element of financial risk and may be addictive. Please play
                responsibly and at your own risk.
              </p>
              <br />
              <h3>What You Can Do:</h3>
              <p>
                If you choose to play on our App, some general guidelines can
                help make your participation experience safer, and reduce the
                risk that problems associated with excessive involvement can
                occur:
              </p>
              <p>Add only the funds that you can afford to spend.</p>
              <p>
                Never add funds that you need for essential things such as food,
                rent, bills, or tuition.
              </p>
              <p>
                Don't participate when you are upset, tired, or depressed. It is
                difficult to make good decisions when you are feeling down.
              </p>
              <p>Do not put off personal and other important tasks</p>
              <p>
                Complete all your important tasks so that you can participate
                with a free and calm mind.
              </p>
              <p>
                Balance your participation with other activities. Find other
                forms of entertainment so participation in DFS does not become
                too big a part of your life.
              </p>
              <p>Do not borrow funds to participate in the App.</p>
              <p>Take time-outs and breaks regularly</p>
              <br />
              <h3>Underage Users:</h3>
              <p>
                The Company adopts a stringent policy against underage
                participants (i.e. Users aged below 18 years). To diminish the
                chance of false/undesired purchases and the possibility of the
                App acting as a source of distraction to those pursuing basic or
                undergraduate education, we ask for identification and
                documentation if we suspect a User is under 18 years. The
                accounts of any Users identified to be underage and
                participating in the App will be deactivated immediately.
              </p>
              <p>
                Although we dedicate a significant amount of time and resources
                to ensure no minors are participating in the App, we feel this
                prevention works best as a shared responsibility between us and
                the minor's parents/guardians. Provided below are a few tips
                that you can follow to ensure that minors, such as your children
                do not participate in our App.
              </p>
              <p>
                Do not leave your devices unattended if you are logged in to the
                App.
              </p>
              <p>
                Be sure to protect your device and set up a password/credential
                control to use and access such devices.
              </p>
              <p>
                Install/employ child protection software on your children's
                devices to prevent them from accessing the App.
              </p>
              <p>
                Use a strong password that is hard to decipher for your User
                Account.
              </p>
              <br />
              <p>
                Do not save your credit/debit card details while adding funds
                and please make sure that undesired funds is not added to your
                account as all transactions made on the App are irreversible and
                funds added to your account has to be used to join paid
                contests, only winning amount(s) can be withdrawn.
              </p>
              <p>
                Maintain close supervision of your User Account activity to
                limit unauthorized access to the App.
              </p>
              <br />
              <h3>Account Closure Request:</h3>
              <p>
                You can close your account at any time for any reason by simply
                contacting our Support Team at  <a href="mailto:support@pickmonk.com">
                  <strong> support@pickmonk.com </strong>
                </a> Please note
                that an account closed under our standard account closure
                mechanism can be reopened at any time by contacting our Support
                Team. However, if you feel you are at risk of developing an
                unhealthy addiction to gaming or believe you currently have an
                unhealthy addiction to gaming, we would advise that you consider
                Take A Break – an explanation of Take A Break is outlined in the
                below section (Take A Break).
              </p>
            
              <p><strong>Take A Break:</strong></p>
              <p>
                We offer a Take A Break facility to help you if you feel that
                your gaming is affecting you negatively and you want assistance
                to help stop. At your request, we will prevent you from using
                your account for a specific period, as determined by you.
              </p>
              <p>
                Should you wish to Take A Break from using your account, you may
                go to the My info and Settings Tab from the menu and at the
                bottom you will find a button "Take A Break" where you can
                choose how many days you want to Take A Break and during this
                period you will not be able to participate in the paid contests,
                however, you can participate in free and practice contests.
              </p>
              <p>
                You will receive an email from the support team upon the Take A
                Break request being operationalized on your User Account.
              </p>
              <p>
                Once the period has lapsed, your account will be fully
                operational. Please note that any Take A Break request granted
                is irreversible for the duration of the time for which it was
                originally operationalized.
              </p>
              <p>
                Entering into Take A Break is a joint commitment. We will take
                reasonable steps to prevent you from re-opening your account or
                opening new accounts with us. However, during this period, you
                must not attempt to re-open your account or open new accounts on
                our App. You have the option to restrict yourself from the
                marketing emails and include yourself in the Do Not Disturb list
                by sending us an email at  <a href="mailto:support@pickmonk.com">
                  <strong> support@pickmonk.com </strong>
                </a>
              </p>
              <br />
              <h3>Block By Company:</h3>
              <p>
                The company may at its discretion, temporarily place a block and
                prevent access to your User Account in case it detects any
                abnormal or unusual activity such as extremely large and
                frequent deposits being made on your User Account. In such
                instances, the company shall also communicate with you to make
                you aware of the potential financial implications of your
                activities. You will receive an email from the Support Team upon
                the company placing any blocks on your User Account.
              </p>
              <p>
                The company may also place a temporary block on your User
                Account in the event the user's Know Your Customer (KYC)
                information has not been provided to the company or is outdated
                until you provide appropriate documentation to the company for
                the company to verify your KYC details.
              </p>
              <br />
              <h3>Consequences Of Exclusions:</h3>
              <p>
                Please note that any block/closure placed by the company will
                apply to your User Account as a whole and you will be prevented
                from participating in any and all contests available on the App
                during any blockage/closure period.
              </p>
              <p>
                You will not receive any marketing emails or newsletters from us
                regarding the App for the period of blockage/closure.
              </p>
              <br />
              <h3>Deposit Limits</h3>
              <p>
                By default, the company permits users to deposit a maximum of Rs
                10,000/- in aggregate, over a period of 24hrs.
              </p>
              <br />
              <h3>What We Can Do To Help You? </h3>
              <b>Age verification:</b>
              <p>
                Any player who has provided dishonest or inaccurate information
                regarding their true age may have all winnings forfeited.
              </p>
              <p>
                Every person signing up for a new account confirms that they are
                at least 18 years of age. This confirms that everyone playing on
                our websites is over the age of 18 years.
              </p>
              <p>
                We do not target underage players with our marketing and
                advertising. It is neither good business nor consistent with our
                personal and corporate values to attract underage players.
              </p>  
              <br />
              <h3>Queries</h3>
              <p>
                In case you have any queries or require any clarifications
                regarding this Responsible Gaming Policy, you can contact us
                through the following channels:
              </p>
              <p>
                Email:{" "}
                <a href="mailto:support@pickmonk.com">
                  <strong>support@pickmonk.com</strong>
                </a>
              </p>

              <p>
                Pickmonk India Private Limited strives to assist a participant
                who requests help and support for the potential negative effects
                of gaming through several means. Users may contact us at 
                <a href="mailto:support@pickmonk.com">
                  <strong> support@pickmonk.com </strong>
                </a> to get assistance in restricting their user
                accounts.
              </p>
              <p>
                If you believe that participating in the contests might be a
                hindrance to your life rather than a form of entertainment, we
                want to help you. We encourage you to send an email to
                <a href="mailto:support@pickmonk.com">
                  <strong> support@pickmonk.com </strong>
                </a> and we will get back to you as quickly as
                possible.
              </p>
            </p>
          </div>
          <div class="trapezoid_left "></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ResponsibleGaming;
