import "./App.css";
import "./utils/styles/content.css";
import phone_logo from "../src/assets/phone_logo.png";
import mail_logo from "../src/assets/mail_logo.png";
import IosBtn from "../src/assets/ios_btn.png";
import PlayStoreBtn from "../src/assets/playstore_btn.png";
import LogoIcon from "../src/assets/Logo.png";
import Footer from "../src/Footer";
import { Link } from "react-router-dom";
import { APP_STORE_URL } from "./utils/constant";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <div className="background-image mb-5">
      <Helmet>
        <title> Privacy Policy </title>        
      </Helmet>
      <div className="header p-md-3 p-2 text-end w-100">
        <b>
          <a href="mailto: support@pickmonk.com">
            <span className="text-light p-md-3 p-1">support@pickmonk.com</span>
            <img src={mail_logo} alt="email icon" />
          </a>
        </b>
        <b>
          <a href="tel:+91 79 35106161">
            <span className="text-light p-md-3 p-2 ps-md-5 ps-4">
              +91 79 35106161
            </span>
            <img src={phone_logo} alt="phone call icon" />
          </a>
        </b>
      </div>
      <Link to="/">
        <img className="logo_icon ms-4 mt-3" src={LogoIcon} alt="pickmonk fantasy app logo" />
      </Link>

      <div className="content_container ">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h1 className="content_box_heading">Privacy Policy</h1>
          </div>
          <div className="col-md-12 col-sm-12 p-3 d-flex align-items-center justify-content-end">
            <div className="row">
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={APP_STORE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="store_icon" src={IosBtn} alt="app store download button" />
                </Link>
              </div>
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={"https://play.google.com/store/apps/details?id=com.pickmonk"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="store_icon" src={PlayStoreBtn} alt="google playstore download button" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div class="trapezoid_right "></div>
          <div className="p-sm-5 p-3 bg-dark">
            <p className="content_box_text">
              All information collected on the Application and/or Platform by
              PICKMONK from the Players, such as registration and credit card
              information, is subject to PICKMONK's Privacy Policy.
              <br />
              <br />
              By using any part of the PICKMONK Platform, the Player consents to
              the collection, use, disclosure, and transfer of their personal
              information for the purposes outlined in the Privacy Policy and
              Terms and Conditions, and to the collection, processing, and
              maintenance of this information. If the player does not agree with
              the Privacy Policy, it is requested to not use the PICKMONK
              Platform and its Services. <br />
              <br />
              The Player using any part and portion of the PICKMONK Platform and
              Services consents to the collection, use, and disclosure of the
              personal information in accordance with the Privacy Policy.
              However, it will be upon the player to provide PICKMONK with
              certain information, and/or withdraw his/her consent to collect
              certain information and/or even request temporary suspension of
              the collection of information, and/or request deletion of
              information collected, please note that in such an event, the
              Player may not be able to take advantage of the entire scope of
              the feature and services offered by PICKMONK Platform and PICKMONK
              reserves its right to not provide its services to their Player(s).
              <br />
              <br />
              To avail of the services on the PICKMONK Platform, the Player will
              be required to provide the following information for the
              registration process namely:
              <br />
              <br />
              <p className="ms-4">
                - Username <br />
                - Mobile <br />
                - Email Address <br />- Date of Birth
              </p>
              In the course of providing the Player access to the features, and
              services on the PICKMONK Platform, the Player may have to provide
              their identity and in securing the account details, may have to
              permit the PICKMONK Platform to capture, record their device
              information, operating system information, network information,
              and location information. The Player may also be required to
              furnish additional information, including the Aadhar Card and/or
              Permanent Account Number. <br />
              <br />
              The Player consents that in certain instances, the PICKMONK
              Platform may also have to collect Sensitive Personal Information
              from the Player on its Platform. Except for any financial
              information that the Player chooses to provide while making
              payment for any kind of Services on the Platform, PICKMONK does
              not collect any other Sensitive Personal Information in the course
              of providing the Services.
              <br />
              <br />
              Any Information collected by PICKMONK shall not be disclosed to
              any third party without the Player's express consent, save as
              otherwise set out in the Terms and Conditions or as provided in a
              separate written agreement or as required by law. However, it is
              clarified that this condition shall not be applicable to publicly
              available information, including Sensitive Personal Information,
              in relation to the Player on the Platform.
              <br />
              <br />
              In the course of providing the Services, the Players may invite
              other existing Players or other Players (hereinafter referred to
              as "Referral Players" and/or "Invited Players") to participate in
              any of the Services by providing their E-mail Address or Username
              of such Players. PICKMONK may thereafter use this information to
              contact the Referral Player and invite such Player to register
              with PICKMONK (if the Referral Player is not an existing Player)
              and participate in the Game in relation to which such person was
              invited by the Player. It is to be clarified that the
              participation of the Referral Player in any of the Contests shall
              be subject to the conditions mentioned herein for the use of the
              Platform as well as the terms stated in the Privacy Policy.
              <br />
              <br />
              The Player hereby agrees that the Referral Player have consented
              and agreed to such disclosure to and use of their E-mail Address
              and Username by PICKMONK Platform.
              <br />
              <br />
              All required information is specific and based on the kind of
              Contest/Game/Service the Player wishes to participate in or
              access, and will be utilized for the purpose of providing
              services, including but not limited to the Services requested by
              the Player. The information has been supplied by the Player
              enables the PICKMONK Platform to improve the Services and provide
              a player–friendly game experience.
              <br />
              <br />
              PICKMONK may also share such information with affiliates and third
              parties in limited circumstances, including for the purpose of
              providing services requested by the Player, complying with the
              legal process, preventing fraud or imminent harm, and ensuring the
              security of the PICKMONK Platform, Services and Network.
              <br />
              <br />
              PICKMONK may also share information as provided by the Player and
              the data concerning usage of the Services and Participation in the
              Contents with third-party service providers engaged by PICKMONK,
              for the purpose of data analytics or other similar purposes, for
              storage and improving the PICKMONK services and the same will be
              provided to the third party services providers after notifying to
              the Players. The Players will also have the opportunity to
              withhold or withdraw their consent in providing the information
              listed herein.
              <br />
              <br />
              By using the Platform, the Player hereby expresses to agree and
              grant consent to the collection, use, and storage of the
              information by PICKMONK and PICKMONK reserves the right to share,
              disclose and transfer the information collected hereunder with its
              affiliates. In the event PICKMONK sells, discloses, or transfers
              all or a portion of its business assets, consumer information or
              transfers the same as a part of any transaction, the Player hereby
              expressly grants consent and permission to PICKMONK for disclosure
              and transfer of such information to such third parties. <br />
              <br />
              The Player expressly agrees and consents that PICKMONK may share
              information as provided by the Player and the data concerning
              usage of the Services and Participation in the Contests with its
              commercial partners for the purpose of facilitating player
              engagement, for marketing and promotional purposes, and other
              related purposes. <br /> <br />
              PICKMONK reserves the right to disclose personal information as
              obligated by law, in response to duly authorized legal processes,
              and governmental requests and as necessary to protect the rights
              and interests of PICKMONK India Private Limited.
              <br /> <br />
              PICKMONK may also include links to other third-party websites.
              Such third-party websites are governed by their respective
              policies, which are beyond PICKMONK's control. Once the Player
              leaves the PICKMONK's servers, use of any information provided by
              the Player shall be governed by the privacy policy and terms and
              conditions of the third-party operator and the third-party website
              the Player is visiting. That policy may differ from the policy of
              PICKMONK's policy and PICKMONK is not responsible for the privacy
              practices or the content of such third-party websites.
              <br />
              <br />
              The Player's personal information may be retained and may be
              continued to be used until the relevant purposes for the use of
              the Player's information as described herein are no longer
              applicable; and PICKMONK is no longer required by the applicable
              law, regulations, contractual obligations or legitimate business
              purposes to retain the personal information and the said retention
              is not required for the establishment, exercise or defense of any
              legal claim.              
            </p>
          </div>
          <div class="trapezoid_left "></div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
