import "./utils/styles/footer.css";
import LogoIcon from "../src/assets/Logo.png";
import IosBtn from "../src/assets/ios_btn.png";
import PlayStoreBtn from "../src/assets/playstore_btn.png";
import { Link } from "react-router-dom";
import { APP_STORE_URL } from  '../src/utils/constant';

function Footer() {

  
  const linkStyle = {    
    textDecoration: "none",
    color: 'white'    
  };

  return (
    <div className="curve mt-5">
      <div className="footer_logo">
      <a href="/">
        <img className="footer_logo_img" src={LogoIcon} alt="pickmonk fantasy app logo" />
      </a>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 col-12 col-sm-12 mb-4">
            <p className="footer_heading w-100 text-center">Connect With Us</p>
            <div className="d-flex align-items-center justify-content-center">
              <div className="dot">
              <Link to='https://www.facebook.com/Pickmonk-india-113255415000432/' target="_blank" rel="noopener noreferrer"><i class="fa fa-facebook icon text-white p-2 hand"></i></Link>
              </div>
              <div className="dot">
                <Link to='https://www.instagram.com/pickmonkindia/' target="_blank" rel="noopener noreferrer"><i class="fa fa-instagram icon text-white p-2 hand"></i></Link>
              </div>
              {/* <div className="dot">
                <i class="fa fa-twitter icon text-white p-2 hand"></i>
              </div>
              <div className="dot me-0">
                <i class="fa fa-google icon text-white p-2 hand"></i>
              </div> */}
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-12">
            <div className="row justify-content-center">
              <div className="col-6 link_" style={{ paddingLeft: "35px" }}>
              <Link  style={linkStyle} to='/content/about-us'><p className="hand"> About</p></Link>
              <Link style={linkStyle} to='/content/how-to-play'><p className="hand">How to Play</p></Link>
              <Link style={linkStyle} to='/content/contact-us'><p className="hand">Contact Us</p></Link>
              <Link style={linkStyle} to='/content/Terms-and-Conditions'><p className="hand">Terms & Conditions</p></Link>
              </div>
              <div className="col-6 link_" style={{ paddingLeft: "30px" }}>
              <Link style={linkStyle}  to='/content/faqs'><p className="hand">FAQ's</p></Link>
              <Link style={linkStyle} to='/content/fair_play'><p className="hand">Fair Play</p></Link>
              <Link style={linkStyle} to='/content/Privacy-Policy'><p className="hand">Privacy Policy</p></Link>
              <Link style={linkStyle} to='/content/Responsible-Gaming'><p className="hand">Responsible Gaming</p></Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 d-flex align-items-end justify-content-center">
            <div className="bg-dark p-1 text-center">
            <Link  to={APP_STORE_URL} target="_blank" rel="noopener noreferrer" ><img
                className="footer_bottom_img hand"
                src={IosBtn}
                alt="app store download button"
                style={{ width: "130px" }}
              />
              </Link>
              <Link  to={"https://play.google.com/store/apps/details?id=com.pickmonk"} target="_blank" rel="noopener noreferrer" ><img
                className="footer_bottom_img hand"
                src={PlayStoreBtn}
                alt="google playstore download button"
                style={{ width: "130px" }}
              /> </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bar">
      © Copyright - Pickmonk India Private Limited. All Rights Reserved.
      </div>
    </div>
  );
}

export default Footer;
