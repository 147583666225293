import "./App.css";
import "./utils/styles/content.css";
import phone_logo from "../src/assets/phone_logo.png";
import mail_logo from "../src/assets/mail_logo.png";
import IosBtn from "../src/assets/ios_btn.png";
import PlayStoreBtn from "../src/assets/playstore_btn.png";
import LogoIcon from "../src/assets/Logo.png";
import Footer from "../src/Footer";
import { Link } from "react-router-dom";
import { APP_STORE_URL, TAGS } from "./utils/constant";
import { Helmet } from "react-helmet";

function About() {
  return (
    <div className="background-image mb-5">
      <Helmet>
        <title> {TAGS["about-us"].title} </title>
        <meta name="description" content={TAGS["about-us"].description} />
        <link rel="canonical" href={TAGS["about-us"].link} />
      </Helmet>
      <div className="header p-md-3 p-2 text-end w-100">
        <b>
          <a href="mailto: support@pickmonk.com">
            <span className="text-light p-md-3 p-1">support@pickmonk.com</span>
            <img src={mail_logo} alt="email icon" />
          </a>
        </b>
        <b>
          <a href="tel:+91 79 35106161">
            <span className="text-light p-md-3 p-2 ps-md-5 ps-4">
              +91 79 35106161
            </span>
            <img src={phone_logo} alt="phone call icon" />
          </a>
        </b>
      </div>
      <Link to="/">
        <img
          className="logo_icon ms-4 mt-3"
          src={LogoIcon}
          alt="pickmonk fantasy app logo"
        />
      </Link>

      <div className="content_container ">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h1 className="content_box_heading">About Us</h1>
          </div>
          <div className="col-md-12 col-sm-12 p-3 d-flex align-items-center justify-content-end">
            <div className="row">
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={APP_STORE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="store_icon"
                    src={IosBtn}
                    alt="app store download button"
                  />
                </Link>
              </div>
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={"https://play.google.com/store/apps/details?id=com.pickmonk"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="store_icon"
                    src={PlayStoreBtn}
                    alt="google playstore download button"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div class="trapezoid_right "></div>
          <div className="p-sm-5 p-3   bg-dark">
            <p className="content_box_text">
            Pickmonk is a Daily Fantasy Sports (DFS) application for sports enthusiasts. The first of its kind with no credits for players and each player is assigned a scoring multiplier (for example, 1x, 1.2x, 2x, etc.). Pickmonk is a platform where sports fans win by quickly selecting their players, and following their performances.
              <br /> <br />
              Ahmedabad-based fantasy sports startup, Pickmonk is India's fastest-growing fantasy sports App for Cricket. Pickmonk will offer Football, Kabaddi, Baseball, and Basketball soon.<br />
              <br />
              Pickmonk is available for download on Apple App Store, and Google Play. Users create virtual teams and earn based on the player's actual performance. These virtual teams garner points based on the actual statistical performance of players during the live match and winners are determined accordingly.<br /> <br />
              Our Vision: To provide the best fantasy sports experience for our users with our unique selection criteria of 11 players with scoring multipliers, as well as quick and easy selections.
            </p>
          </div>
          <div class="trapezoid_left "></div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
