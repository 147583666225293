import "./App.css";
import "./utils/styles/content.css";
import phone_logo from "../src/assets/phone_logo.png";
import mail_logo from "../src/assets/mail_logo.png";
import IosBtn from "../src/assets/ios_btn.png";
import PlayStoreBtn from "../src/assets/playstore_btn.png";
import HowToPlayIcon from "../src/assets/how_to_play_icon.svg";
import LogoIcon from "../src/assets/Logo.png";
import Footer from "../src/Footer";
import { Link } from "react-router-dom";
import { APP_STORE_URL , TAGS } from "./utils/constant";
import { Helmet } from "react-helmet";

function HowToPlay() {
  return (
    <div className="background-image mb-5">
      <Helmet>
        <title> {TAGS["how-to-play"].title} </title>
        <meta name="description" content={TAGS["how-to-play"].description} />
        <link rel="canonical" href={TAGS["how-to-play"].link} />
      </Helmet>
      <div className="header p-md-3 p-2 text-end w-100">
        <b>
          <a href="mailto: support@pickmonk.com">
            <span className="text-light p-md-3 p-1">support@pickmonk.com</span>
            <img src={mail_logo} alt="email icon" />
          </a>
        </b>
        <b>
          <a href="tel:+91 79 35106161">
            <span className="text-light p-md-3 p-2 ps-md-5 ps-4">
              +91 79 35106161
            </span>
            <img src={phone_logo} alt="phone call icon" />
          </a>
        </b>
      </div>
      <Link to="/">
        <img className="logo_icon ms-4 mt-3" src={LogoIcon} alt="pickmonk fantasy app logo" />
      </Link>

      <div className="content_container ">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h1 className="content_box_heading">How To Play</h1>
          </div>
          <div className="col-md-12 col-sm-12 p-3 d-flex align-items-center justify-content-end">
            <div className="row">
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={APP_STORE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="store_icon" src={IosBtn} alt="app store download button" />
                </Link>
              </div>
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={"https://play.google.com/store/apps/details?id=com.pickmonk"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="store_icon" src={PlayStoreBtn} alt="google playstore download button" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div class="trapezoid_right "></div>
          <div className="p-sm-5 p-3 bg-dark">
            <p className="content_box_text">
            Pickmonk is India's fastest-growing fantasy sports App for Cricket. Pickmonk will offer Football, Kabaddi, Baseball, and Basketball soon.{" "}
            </p>
            <p className="content_box_text">
            Pickmonk is the first of its kind for its unique Selection Criteria of 11 players with scoring multipliers.{" "}
            </p>
            <p className="content_box_text">
            Please follow three simple steps to register, join contests, create teams, and win big.
              <br /> <br />
              <span className="content_box_green_heading">
                Step 1: Register
              </span>{" "}
              <br />
              <div className="how_to_play_text_container">
                <div>
                  <img
                    className="content_box_green_heading_icon"
                    src={HowToPlayIcon}
                    alt="arrow icon"
                  />
                </div>
                <div>
                  <span>
                    {" "}
                    Download the app from Apple App Store, or Google Play and register via Mobile and Email.
                  </span>
                </div>
              </div>
              <br />
              <span className="content_box_green_heading">
                Step 2: Select a Match and Join Contests
              </span>{" "}
              <br />
              <div className="how_to_play_text_container">
                <div>
                  <img
                    className="content_box_green_heading_icon"
                    src={HowToPlayIcon}
                    alt="arrow icon"
                  />
                </div>
                <div>
                  <span>Select a match from the upcoming matches.</span>
                </div>
              </div>{" "}              
              <div className="how_to_play_text_container">
                <div>
                  <img
                    className="content_box_green_heading_icon"
                    src={HowToPlayIcon}
                    alt="arrow icon"
                  />
                </div>
                <div>
                  <span>
                  Join any of the contests for the particular match.
                  </span>
                </div>
              </div>{" "}
              <br />              
              <span className="content_box_green_heading">
                {" "}
                Step 3: Create Your Team
              </span>{" "}
              <br />
              <div className="how_to_play_text_container">
                <div>
                  <img
                    className="content_box_green_heading_icon"
                    src={HowToPlayIcon}
                    alt="arrow icon"
                  />
                </div>
                <div>
                  <span>
                  For a given contest, select 11 players to create your team
                  </span>
                </div>
              </div>{" "}              
              <div className="how_to_play_text_container">
                <div>
                  <img
                    className="content_box_green_heading_icon"
                    src={HowToPlayIcon}
                    alt="arrow icon"
                  />
                </div>
                <div>
                  <span>
                  Select your super selection and multiplier and create your team.
                  </span>
                </div>
              </div>{" "}             
            </p>
          </div>
          <div class="trapezoid_left "></div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default HowToPlay;
