import "./App.css";
import "./utils/styles/content.css";
import phone_logo from "../src/assets/phone_logo.png";
import mail_logo from "../src/assets/mail_logo.png";
import LogoIcon from "../src/assets/Logo.png";
import Footer from "../src/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";

function PointCalculations() {
  return (
    <div className="background-image mb-5">
      <Helmet>
        <title> Point Calculations </title>
      </Helmet>
      <div className="header p-md-3 p-2 text-end w-100">
        <b>
          <a href="mailto: support@pickmonk.com">
            <span className="text-light p-md-3 p-1">support@pickmonk.com</span>
            <img src={mail_logo} alt="email icon" />
          </a>
        </b>
        <b>
          <a href="tel:+91 79 35106161">
            <span className="text-light p-md-3 p-2 ps-md-5 ps-4">
              +91 79 35106161
            </span>
            <img src={phone_logo} alt="phone call icon" />
          </a>
        </b>
      </div>
      <Link to="/">
        <img
          className="logo_icon ms-4 mt-3"
          src={LogoIcon}
          alt="pickmonk fantasy app logo"
        />
      </Link>

      <div className="content_container ">
        <div className="row">
          <div className="col-md-12 col-sm-12 p-4 py-5 d-flex align-items-center">
            <h1 className="content_box_heading" style={{fontSize:60}}>Point Calculations</h1>
          </div>
          {/* <div className="col-md-12 col-sm-12 p-3 d-flex align-items-center justify-content-end">
            <div className="row">
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={APP_STORE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="store_icon"
                    src={IosBtn}
                    alt="app store download button"
                  />
                </Link>
              </div>
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={PLAY_STORE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="store_icon"
                    src={PlayStoreBtn}
                    alt="google playstore download button"
                  />
                </Link>
              </div>
            </div>
          </div> */}
        </div>
        <div className="row justify-content-center">
          <div class="trapezoid_right "></div>
          <div className="p-sm-5 p-3   bg-dark">
            <p className="content_box_text">
              <p>
                {/* The game is a skill - based fantasy game wherein the players will have
        to create their own team of 11 players based on the player's past
        performance (wicket keepers. batsman and bowlers).&nbsp;
      </p>
      <p>
        The Players will pick exact 11 players (from Wicket Keepers, Batsman and
        Bowlers who are divided in 11 different categories) from both the playing
        teams with given 15 salary points and minimum 1 player(s) is to be
        selected from each team. */}
                Pickmonk is a skill-based fantasy game where users select 11 players with scoring multipliers and create their team.</p>
              <p>There are no player credits to juggle. You simply pick any player and make your team of 11 players.</p>
              <p>A user can pick a maximum of 10 players from one team and must have at least one player from the opposition.</p>
              <p>User must select at least player from each category (Batsman, Bowler, All Rounder, Wicket Keeper).</p>
              <br></br>

              {/* <p>
        <img src={PointCal1} alt="pickmonk point calculation screenshot1" />
      </p> */}

              {/* <h3 className="green-font">Scoring Rules</h3> */}
              {/* <h3 className="green-font">How it works</h3> */}
              {/* <ul>
                <li>
                  Players are categorized into each category based on their past
                  performance and current performance in the ongoing series. A
                  player who has been consistent with a good past performance
                  could be listed amongst the highest categories (5 credits
                  category, 4 credits category etc.), similarly players who are
                  struggling in the ongoing series or struggled in recent
                  performances will find themselves listed in the lower
                  categories (2 credits, 1 credit etc.).
                </li>
                <li>&nbsp;</li>
                <li>
                  {/* Category <strong>A</strong> is regarded as highly trusted and capable
          allrounder players and wk. each point of player is multiply with{" "}
          <strong>1x</strong> */}
                  {/* <strong>5</strong> credits Category players will receive{" "}
                  <strong className="blue-font" >1x</strong> the fantasy points they score.
                </li>
                <li>&nbsp;</li> */}
                {/* <li>
          Category <strong>B</strong> is regarded as mixed all rounder and
          batsman's. Each points of player is multiply with <strong>1.2x</strong>
        </li> */}
                {/* <strong>4</strong> credits Category players will receive{" "}
                <strong className="blue-font">1.2x</strong> the fantasy points they score.
                <li>&nbsp;</li> */}
                {/* <li>
          Category <strong>C</strong> is regarded as Batsman and bowlers. Each
          points of player is multiply with <strong>1.4x</strong>
        </li> */}
                {/* <strong>3</strong> credits Category players will receive{" "}
                <strong className="blue-font">1.4x</strong> the fantasy points they score.
                <li>&nbsp;</li> */}
                {/* <li>
          Category <strong>D</strong> is regarded allrounder and bowlers. Each
          points of player is multiply with <strong>1.6x</strong>
        </li> */}
                {/* <strong>2</strong> credits Category players will receive{" "}
                <strong className="blue-font">1.6x</strong> the fantasy points they score.
                <li>&nbsp;</li> */}
                {/* <li>
          Category <strong>E</strong> is regarded bowlers. Each points of player
          is multiply with <strong>2x</strong>
        </li> */}
                {/* <strong>1</strong> credits Category players will receive{" "}
                <strong className="blue-font">2x</strong> the fantasy points they score.
                <li>&nbsp;</li>
                <li>&nbsp;</li> */}
                {/* <li>
          Category <strong>E</strong> is regarded bowlers. Each points of player
          is multiply with <strong>2x</strong>
        </li> */}
                {/* A user can select a maximum of 4 players from one team and must
                have one player from the opposition.
                <li>&nbsp;</li>
              </ul> */} 
              {/* <ol>
        <li>
          Minimum playes in your one team is 11 players among the 50 players and
          you can play cricket league with them. And you can select only 4
          player from one team.
        </li>
        <li>&nbsp;</li>
        <li>
          Super selection is a player that you select as the another 4 players
          captain which is most valuable player among the all team players which
          total points is 2x more than actual points, and as shown in image
          super selection player background is blue
        </li>
        <li>
          Multiplier is a player which is known as the vice-captain of the team
          which total point is <strong>1.5x</strong> more than actual points as
          shown in image background color of multiplier is orange.
        </li>
      </ol> */}

              <h3 className="green-font">
                Super Selection and Multiplier
              </h3>
              <ul>
                <li >
                 <span style={{color:"#00C4FE"}}>Super Selection:</span>  Users must designate one of their 11 players
                  as a Super Selection. A player marked as the Super Selection
                  receives an additional{" "}
                  <strong style={{ color: "#00C4FE" }} className="blue-font">2x</strong> fantasy
                  points added to their total.
                </li>
                <li>&nbsp;</li>
                <li>
                <span style={{color:"#00C4FE"}}>Multiplier:</span>  Users must designate one of their 11 players as a
                  Multiplier. A player marked as the Multiplier receives an
                  additional <strong style={{ color: "#00C4FE" }} className="blue-font">1.5x</strong>{" "}
                  fantasy points added to their total.
                </li>
              </ul>
              <h3
                style={{ marginTop: 30 }}
                className="green-font"
              >
                Points Calculation
              </h3>
              <div className="calc-run-text-div" style={{  flex: 1 }}>
                <div style={{ float: "left", flex: 0.3 }}>
                  <p className="blue-font">Batting Points Calculation</p>
                  <figure class="table">
                    <table cellSpacing={10} cellPadding={10} >
                      <thead style={{ backgroundColor: "#14F45E3D" }}>
                        <tr className="calc-run-text">
                          <th style={{width:"100%"}}>&nbsp;</th>
                          <th style={{width:"12%"}}>T20</th>
                          <th style={{width:"12%"}}>ODI</th>
                          <th style={{width:"12%"}}>T10</th>
                          <th style={{width:"12%"}}>Test</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          className="calc-run-text"
                          style={{ backgroundColor: "#3F3F3F" }}
                        >
                          <th>Run</th>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                        </tr>
                        <tr className="calc-run-text">
                          <th>Boundry Bonus</th>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                        </tr>
                        <tr
                          className="calc-run-text"
                          style={{ backgroundColor: "#3F3F3F" }}
                        >
                          <th>Six Bonus</th>
                          <td >2</td>
                          <td>2</td>
                          <td>2</td>
                          <td>2</td>
                        </tr>
                        <tr className="calc-run-text">
                          <th>30 Run Bonus</th>
                          <td>4</td>
                          <td>0</td>
                          <td>8</td>
                          <td>0</td>
                        </tr>
                        <tr
                          className="calc-run-text"
                          style={{ backgroundColor: "#3F3F3F" }}
                        >
                          <th>Half Century Bonus</th>
                          <td>8</td>
                          <td>4</td>
                          <td>16</td>
                          <td>4</td>
                        </tr>
                        <tr className="calc-run-text">
                          <th>Century Bonus</th>
                          <td>16</td>
                          <td>8</td>
                          <td>0</td>
                          <td>8</td>
                        </tr>
                        <tr
                          className="calc-run-text"
                          style={{ backgroundColor: "#3F3F3F" }}
                        >
                          <th>Dismissal for a Duck</th>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                      </tbody>
                    </table>
                  </figure>
                </div>

                <div
                  style={{
                    float: "left",
                    flex: 0.3,
                    marginLeft: isMobile ? 0 : 10,
                    marginRight: isMobile ? 0 : 10,
                  }}
                >
                  <p className="blue-font">Bowling Points Calculation</p>
                  <figure class="table">
                    <table cellSpacing={10} cellPadding={10} >
                      <thead style={{ backgroundColor: "#14F45E3D" }}>
                        <tr className="calc-run-text">
                        <th style={{width:"100%"}}>&nbsp;</th>
                          <th style={{width:"12%"}}>T20</th>
                          <th style={{width:"12%"}}>ODI</th>
                          <th style={{width:"12%"}}>T10</th>
                          <th style={{width:"12%"}}>Test</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          className="calc-run-text"
                          style={{ backgroundColor: "#3F3F3F" }}
                        >
                          <th>Wicket</th>
                          <td>25</td>
                          <td>25</td>
                          <td>25</td>
                          <td>16</td>
                        </tr>
                        <tr className="calc-run-text">
                          <th>Bonus (LBW/Bowled)</th>
                          <td>8</td>
                          <td>8</td>
                          <td>8</td>
                          <td>8</td>
                        </tr>
                        <tr
                          className="calc-run-text"
                          style={{ backgroundColor: "#3F3F3F" }}
                        >
                          <th>3 Wicket Bonus</th>
                          <td>4</td>
                          <td>0</td>
                          <td>8</td>
                          <td>0</td>
                        </tr>
                        <tr className="calc-run-text">
                          <th>4 Wicket Bonus</th>
                          <td>8</td>
                          <td>4</td>
                          <td>16</td>
                          <td>4</td>
                        </tr>
                        <tr
                          className="calc-run-text"
                          style={{ backgroundColor: "#3F3F3F" }}
                        >
                          <th>5 Wicket Bonus</th>
                          <td>16</td>
                          <td>8</td>
                          <td>0</td>
                          <td>8</td>
                        </tr>
                        <tr className="calc-run-text">
                          <th>Maiden Over</th>
                          <td>12</td>
                          <td>4</td>
                          <td>16</td>
                          <td>0</td>
                        </tr>
                      </tbody>
                    </table>
                  </figure>
                </div>
                <div style={{ float: "left", flex: 0.3 }}>
                  <p className="blue-font">
                    Fielding Points Calculation
                  </p>
                  <figure class="table">
                    <table cellSpacing={10} cellPadding={10} >
                      <thead style={{ backgroundColor: "#14F45E3D" }}>
                        <tr className="calc-run-text">
                        <th style={{width:"100%"}}>&nbsp;</th>
                          <th style={{width:"12%"}}>T20</th>
                          <th style={{width:"12%"}}>ODI</th>
                          <th style={{width:"12%"}}>T10</th>
                          <th style={{width:"12%"}}>Test</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          className="calc-run-text"
                          style={{ backgroundColor: "#3F3F3F" }}
                        >
                          <th>Catch</th>
                          <td>8</td>
                          <td>8</td>
                          <td>8</td>
                          <td>8</td>
                        </tr>
                        <tr className="calc-run-text">
                          <th>3 Catch Bonus</th>
                          <td>4</td>
                          <td>4</td>
                          <td>4</td>
                          <td>0</td>
                        </tr>
                        <tr
                          className="calc-run-text"
                          style={{ backgroundColor: "#3F3F3F" }}
                        >
                          <th>Stumping</th>
                          <td>12</td>
                          <td>12</td>
                          <td>12</td>
                          <td>12</td>
                        </tr>
                        <tr className="calc-run-text">
                          <th>Runout Direct</th>
                          <td>12</td>
                          <td>12</td>
                          <td>12</td>
                          <td>12</td>
                        </tr>
                        <tr
                          className="calc-run-text"
                          style={{ backgroundColor: "#3F3F3F" }}
                        >
                          <th>Runout Not Direct</th>
                          <td>6</td>
                          <td>6</td>
                          <td>6</td>
                          <td>6</td>
                        </tr>
                      </tbody>
                    </table>
                  </figure>
                </div>
              </div>
              {/* <p>
        Here refer this image for individual players total calculation of points
        as shown above first it show that player is announced or not.
      </p>
      <p>
        Then show player run, wicket, wicket bonus runout direct etc details.
      </p>
      <p>
      
        &nbsp;
      </p>
      <p>
        <strong>Point calculation of this (below) player is:</strong>
      </p>
      <p>
        Abdul Bangaizal’s total run 46 so total runs points is 46
      
        Abdul Bangaizal’s &nbsp;gets 30 runs bonus which would be 4<br />
        Abdul Bangaizal’s &nbsp;gets 1 catch bouns which would be 8<br />
        Abdul Bangaizal’s &nbsp;gets 2 four runs bouns which would be 2<br />
        Abdul Bangaizal’s &nbsp;gets 3 six runs bouns which would be 6<br />
        <strong>
          Total points Abdul gets (46+4+8+2+6 = 66) * 1.4 = 92.4 &nbsp;
        </strong>
      
        &nbsp;
      </p>
      <p>
      
        &nbsp;
      </p>
      <p>
        <img src={PointCal2} alt="pickmonk point calculation screenshot2" />
      </p> */}
            </p>
          </div>
          <div class="trapezoid_left "></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PointCalculations;
