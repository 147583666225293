import "./App.css";
import { useEffect} from "react";
import "./utils/styles/content.css";
import { APP_STORE_URL } from "./utils/constant";

function ApkDownload () {
  function getMobileOperatingSystem () {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "Android";
}

useEffect(() => {
  if( getMobileOperatingSystem() === "Android"){
  

    window.location.href="https://play.google.com/store/apps/details?id=com.pickmonk";

  }else if( getMobileOperatingSystem()==="iOS"){
    window.location.href=APP_STORE_URL;
  
    
  }else{
   
    window.close();
  }
}, []);
}

export default ApkDownload;
