import "./App";
import "../src/utils/styles/home_section_2.css";
import Iphone from "./assets/home_section_2/iPhone 13.png";
import Card from "./assets/home_section_2/ReferAndEarn_circleicon.png";
import Tag from "./assets/home_section_2/PlatformFees_circleicon.png";
import Camera from "./assets/home_section_2/InstantWithdrawals_circleicon.png";
import Star from "./assets/home_section_2/JoiningFees__circleicon.png";
import Live from "./assets/home_section_2/LiveLeaderboard_circleicon.png";
import Group from "./assets/home_section_2/CreateTeam_circleicon.png";
import Join from "./assets/home_section_2/PaidContest_circleicon.png";
import Player from "./assets/home_section_2/SelectMatch_circleicon.png";

function HomeSection2() {
  return (
    <div>
      <div className="container">
        <div className="row  rect  justify-content-center align-items-center">
          <h2 className="section1_main_heading">3 Easy Steps</h2>
          <div className="col-md-4 col-12 col-sm-12">
            <div className="row justify-content-center">
              <img className="img_" src={Player} alt="select a player icon" />
              <h2 className="section1_text_heading">Select a match</h2>
              <h6 className="section1_text">
                Select an upcoming match of your choice
              </h6>
            </div>
          </div>
          <div className="col-md-4 col-12 col-sm-12 ">
            <div className="row justify-content-center">
              <img className="img_" src={Group} alt="create your own team icon" />
              <h2 className="section1_text_heading">Create your own team</h2>
              <h6 className="section1_text">
                    Create your team of 11 players.
              </h6>
            </div>
          </div>
          <div className="col-md-4 col-12 col-sm-12 ">
            <div className="row justify-content-center">
              <img className="img_" src={Join} alt="free contest icon" />
              <h2 className=" section1_text_heading">
                Join contests
              </h2>
              <h6 className="section1_text">
                Participate in contests.
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div className="container section_2_container">
        <div className="col-md-6 col-12">
          <h2 className="section2_heading p-2">
            Play Fantasy Cricket on Pickmonk
          </h2>          
        </div>
        <div className="row dots_bg">
          <div className="col-12 col-md-6">
            <p className="section2_text p-2">
              {" "}
              Pickmonk is a Daily Fantasy Sports (DFS) application for sports enthusiasts. The first of its kind with no credits for players and each player is assigned a scoring multiplier (for example, 1x, 1.2x, 2x, etc.). Pickmonk is a platform where sports fans win by quickly selecting their players, following their performances.
              <br />
              <br />
              Pickmonk is available for download on Apple App Store, and Google Play. Users create virtual teams and earn based on the player's actual performance. These virtual teams garner points based on the actual statistical performance of players during the real match and winners are determined accordingly.<br />
              <br />
              Pickmonk offers daily contests for Cricket fans and provides the best fantasy sports experience with our unique selection criteria of 11 players with scoring multipliers as well as quick and easy selections.<br />
            </p>
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <img className="section2_img" src={Iphone} alt="Pickmonk app screenshot 2" />
            </div>
          </div>
        </div>
      </div>

      <div className="container section_3_container">
        <div className="row text-center mb-5">
          <h2 className="section3_main_heading">Features</h2>
        </div>

        <div className="row justify-content-start ms-md-5">
          <div
            className="col-lg-4 col-sm-8 col-8 d-flex align-items-center"
            style={{ textAlign: "right" }}
          >
            <div className="row">
              <p className="section3_heading">Live Leaderboard </p>
              <p className="section3_text">
                Track your fantasy points and ranks with a Live Leaderboard
              </p>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4 col-4 d-flex align-item-right">
            <div className="row">
              <img className="section3_img" src={Live} alt="live leaderboard icon" />
            </div>
          </div>
        </div>

        <div className="row justify-content-end me-md-5 section3_margin">
          <div className="col-lg-2 col-sm-4 col-4">
            <div className="row justify-content-end">
              <img className="section3_img" src={Tag} alt="low platform fees icon" />
            </div>
          </div>
          <div className="col-lg-4 col-sm-8 col-8 d-flex align-items-center style={{textAlign:'left'}}">
            <div className="row">
              <p className="section3_heading">Low Platform Fees </p>
              <p className="section3_text">
                Maximise your winnings with the lowest platform fees
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-start ms-md-5 section3_margin">
          <div
            className="col-lg-4 col-sm-8 col-8 d-flex align-items-center"
            style={{ textAlign: "right" }}
          >
            <div className="row">
              <p className="section3_heading">Minimum Joining Fees </p>
              <p className="section3_text">
                Join contests for minimum joining fees
              </p>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4 col-4 d-flex align-item-right">
            <div className="row">
              <img className="section3_img" src={Star} alt="minimum joining fees icon" />
            </div>
          </div>
        </div>

        <div className="row justify-content-end me-md-5 section3_margin">
          <div className="col-lg-2 col-sm-4 col-4">
            <div className="row justify-content-end">
              <img className="section3_img" src={Camera} alt="instant withdrawl icon" />
            </div>
          </div>
          <div className="col-lg-4 col-sm-8 col-8 d-flex align-items-center style={{textAlign:'left'}}">
            <div className="row">
              <p className="section3_heading">Instant Withdrawals</p>
              <p className="section3_text">
                Instant withdrawals of your winnings
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-start ms-md-5 section3_margin">
          <div
            className="col-lg-4 col-sm-8 col-8 d-flex align-items-center"
            style={{ textAlign: "right" }}
          >
            <div className="row">
              <p className="section3_heading">Refer and Earn </p>
              <p className="section3_text">
                Refer your friends and family and enjoy a referral bonus of
                Rs.100
              </p>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4 col-4 d-flex align-item-right">
            <div className="row">
              <img className="section3_img" src={Card} alt="refer and earn icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSection2;
