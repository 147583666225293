import "./App.css";
import "./utils/styles/content.css";
import phone_logo from "../src/assets/phone_logo.png";
import mail_logo from "../src/assets/mail_logo.png";
import IosBtn from "../src/assets/ios_btn.png";
import PlayStoreBtn from "../src/assets/playstore_btn.png";
import LogoIcon from "../src/assets/Logo.png";
import Footer from "../src/Footer";
import { Link } from "react-router-dom";
import { APP_STORE_URL , TAGS } from "./utils/constant";
import { Helmet } from "react-helmet";

function FairPlay() {
  return (
    <div className="background-image mb-5">
       <Helmet>
        <title> {TAGS["fair_play"].title} </title>
        <meta name="description" content={TAGS["fair_play"].description} />
        <link rel="canonical" href={TAGS["fair_play"].link} />
      </Helmet>
      <div className="header p-md-3 p-2 text-end w-100">
        <b>
          <a href="mailto: support@pickmonk.com">
            <span className="text-light p-md-3 p-1">support@pickmonk.com</span>
            <img src={mail_logo} alt="email icon" />
          </a>
        </b>
        <b>
          <a href="tel:+91 79 35106161">
            <span className="text-light p-md-3 p-2 ps-md-5 ps-4">
              +91 79 35106161
            </span>
            <img src={phone_logo} alt="phone call icon" />
          </a>
        </b>
      </div>
      <Link to="/">
        <img className="logo_icon ms-4 mt-3" src={LogoIcon} alt="pickmonk fantasy app logo" />
      </Link>

      <div className="content_container ">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h1 className="content_box_heading">Fair Play</h1>
          </div>
          <div className="col-md-12 col-sm-12 p-3 d-flex align-items-center justify-content-end">
            <div className="row">
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={APP_STORE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="store_icon" src={IosBtn} alt="app store download button" />
                </Link>
              </div>
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={"https://play.google.com/store/apps/details?id=com.pickmonk"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="store_icon" src={PlayStoreBtn} alt="google playstore download button" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div class="trapezoid_right "></div>
          <div className="p-sm-5 p-3   bg-dark">          
            <p className="content_box_text">
              <span>
                ” Life’s more fun with skin in the game, and any unfairness gets
                in the way. We’re committed to maintaining a fair and level
                playing field to preserve the competitiveness of the game. “
              </span>{" "}
              <br /> <br />
              <span className="content_box_green_heading">
                If you see something suspicious, report it to us.
              </span>{" "}
              <br />
              <span>
                Don’t be afraid to throw a challenge flag. We take player
                submitted concerns seriously. If you suspect that another
                player(s) may be engaging in behavior that you feel negatively
                impacts the integrity of our contests.
              </span>{" "}
              <br /> <br />
              <span className="content_box_green_heading">
                Have an account related question?
              </span>{" "}
              <br />
              <span>
                The Pickmonk Player Support Team can help with account or
                product related questions whenever they arise.
              </span>{" "}
              <br /> <br />
              <span className="content_box_green_heading">
                {" "}
                Committed to play responsibly
              </span>{" "}
              <br />
              We’re committed to a safe and reliable place for online gaming. We
              want to provide tools to help everyone play within their financial
              means. If you think you’re spending too much time or funds on
              Pickmonk, we can help.
            </p>
          </div>
          <div class="trapezoid_left "></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FairPlay;
