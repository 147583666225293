import "./App.css";
import "./utils/styles/content.css";
import phone_logo from "../src/assets/phone_logo.png";
import mail_logo from "../src/assets/mail_logo.png";
import IosBtn from "../src/assets/ios_btn.png";
import PlayStoreBtn from "../src/assets/playstore_btn.png";
import Footer from "../src/Footer";
import { Link } from "react-router-dom";
import LogoIcon from "../src/assets/Logo.png";
import { APP_STORE_URL , TAGS } from "./utils/constant";
import { Helmet } from "react-helmet";

function Contact() {
  return (
    <div className="background-image mb-5">
      <Helmet>
        <title> {TAGS["contact-us"].title} </title>
        <meta name="description" content={TAGS["contact-us"].description} />
        <link rel="canonical" href={TAGS["contact-us"].link} />
      </Helmet>
      <div className="header p-md-3 p-2 text-end w-100">
        <b>
          <a href="mailto: support@pickmonk.com">
            <span className="text-light p-md-3 p-1">support@pickmonk.com</span>
            <img src={mail_logo} alt="email icon" />
          </a>
        </b>
        <b>
          <a href="tel:+91 79 35106161">
            <span className="text-light p-md-3 p-2 ps-md-5 ps-4">
              +91 79 35106161
            </span>
            <img src={phone_logo} alt="phone call icon" />
          </a>
        </b>
      </div>
      <Link to="/">
        <img className="logo_icon ms-4 mt-3" src={LogoIcon} alt="pickmonk fantasy app logo" />
      </Link>

      <div className="content_container ">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h1 className="content_box_heading">Contact Us</h1>
          </div>
          <div className="col-md-12 col-sm-12 p-3 d-flex align-items-center justify-content-end">
            <div className="row">
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={APP_STORE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="store_icon" src={IosBtn} alt="app store download button" />
                </Link>
              </div>
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={"https://play.google.com/store/apps/details?id=com.pickmonk"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="store_icon" src={PlayStoreBtn} alt="google playstore download button" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div class="trapezoid_right "></div>
          <div className="p-sm-5 p-3 bg-dark ">
          <div className="d-md-flex align-items-center">
            <p className="text-light about_box_heading">Email : </p>
            <a href="mailto: support@pickmonk.com">
              <p className="content_box_text contact_content">
                support@pickmonk.com
              </p>
            </a>
          </div>

          <div className="bg-dark d-md-flex pt-4 ">
            <p className="text-light about_box_heading">Address : </p>
            <p className="about_box_heading mt-2 contact_content">
              <span className="content_box_text ml-5">
                Pickmonk India Private Limited <br /> <br />
                Mobile : <a href="tel:+91 79 35106161">
                  +91 79 35106161
                </a>{" "}
                <br /> <br />
                401 Silver Radiance Sindhu Bhavan Marg <br /> New Pakwan Cross
                Road <br />
                Thaltej, Ahmedabad 380059 <br /> Gujarat, India
              </span>
            </p>
          </div>
          </div>
         

          <div class="trapezoid_left "></div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Contact;
