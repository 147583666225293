import "./App.css";
import "./utils/styles/content.css";
import phone_logo from "../src/assets/phone_logo.png";
import mail_logo from "../src/assets/mail_logo.png";
import IosBtn from "../src/assets/ios_btn.png";
import PlayStoreBtn from "../src/assets/playstore_btn.png";
import LogoIcon from "../src/assets/Logo.png";
import Footer from "../src/Footer";
import { Link } from "react-router-dom";
import { APP_STORE_URL } from "./utils/constant";
import { Helmet } from "react-helmet";

function TermsAndConditions() {
  return (
    <div className="background-image mb-5">
      <Helmet>
        <title> Terms & Conditions </title>
      </Helmet>
      <div className="header p-md-3 p-2 text-end w-100">
        <b>
          <a href="mailto: support@pickmonk.com">
            <span className="text-light p-md-3 p-1">support@pickmonk.com</span>
            <img src={mail_logo} alt="email icon" />
          </a>
        </b>
        <b>
          <a href="tel:+91 79 35106161">
            <span className="text-light p-md-3 p-2 ps-md-5 ps-4">
              +91 79 35106161
            </span>
            <img src={phone_logo} alt="phone call icon" />
          </a>
        </b>
      </div>
      <Link to="/">
        <img className="logo_icon ms-4 mt-3" src={LogoIcon} alt="pickmonk fantasy app logo" />
      </Link>

      <div className="content_container">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h1 className="content_box_heading">Terms & Conditions</h1>
          </div>
          <div className="col-md-12 col-sm-12 p-3 d-flex align-items-center justify-content-end">
            <div className="row">
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={APP_STORE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="store_icon" src={IosBtn} alt="app store download button" />
                </Link>
              </div>
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={"https://play.google.com/store/apps/details?id=com.pickmonk"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="store_icon" src={PlayStoreBtn} alt="google playstore download button" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div class="trapezoid_right "></div>
          <div className="p-sm-5 p-3  bg-dark">
            <p className="content_box_text">
              <h3 className="content_box_green_heading">
                Modus Operandi of PICKMONK{" "}
              </h3>
              PICKMONK is a flagship brand of Pickmonk India Private Limited,
              having its registered office in Ahmedabad, Gujarat. Pickmonk
              offers cricket-based online daily fantasy contests through its
              website and mobile application. Pickmonk will offer Football,
              Kabaddi, Baseball, and Basketball soon.
              <br /> <br />
              <h3 className="content_box_green_heading">Usage of PICKMONK</h3>
              Any person (hereinafter referred to as "user" and/or "player")
              accessing the Website of PICKMONK or the Application of PICKMONK
              (hereinafter referred to as "PICKMONK platform") for participating
              in the online daily fantasy games, contests, and various games
              available on its platform (hereinafter referred to as "PICKMONK
              CONTESTS" and/or "contests") and using the PICKMONK platform for
              the various services available and provided on the website and the
              application (hereinafter referred to as "PICKMONK Services" and/or
              "Services") shall be bound by the following Terms and Conditions,
              and all other rules, regulations, and terms of use referred to
              herein below and/or provided by PICKMONK in relation to its
              services.
              <br />
              <br />
              For the purpose of these Terms and Conditions, the definition of
              "Software" will include the PICKMONK web-accessible website which
              will be accessed by the Players. The Mobile Version (hereinafter
              referred to as "Mobile Software") is accessible on Android and iOS
              Operating Systems (including, without limitation, a cellular
              phone, PDA, tablet, or any other type of device now existing or
              hereafter devised)(hereinafter referred to as "Mobile Device") as
              well as all ancillary software to the Fantasy Sports software.
              <br /> <br />
              By accessing and using the platform, the player expressly agrees
              to comply with the Terms and Conditions of Use as well as the
              Privacy Policy. If the Player does not agree with any of the below
              Terms and Conditions, the player will be refrained from using the
              application and the website. PICKMONK India Private Limited
              reserves the right, in its sole discretion, to modify, alter
              and/or otherwise update these Terms and Conditions of Use and its
              Privacy Policy at any time. Any changes to these Terms that may
              affect the rights of the Player will be alerted to the Player on
              the homepage for the first week, in which such change is made and
              notified through the player's registered E-mail ID. The player may
              then exercise the options provided in such an Email or
              Notification to indicate non – acceptance of the modified Terms
              and Conditions, rules, regulations, and terms of use referred to
              herein or provided by PICKMONK. If such options are not exercised
              by the Player within the time frame prescribed in the Email or
              Notification, the Player will be deemed to have accepted the
              modified Terms and Conditions, Rules, Regulations, and Terms of
              Use referred to herein. Furthermore, certain PICKMONK Services
              being provided on the platform may be subject to additional rules
              and regulations set down in that particular respect, and to that
              extent, if these Terms and Conditions are inconsistent with the
              additional conditions set down, the additional terms and
              conditions shall prevail.
              <br /> <br />
              By using this service, the Players agree to be bound by the Terms
              and Conditions.
              <br /> <br />
              By clicking the "I Agree" checkbox as part of registration, the
              player consents to the Terms and Conditions set forth, the Privacy
              Policy, Rules and Regulations, and terms of use and Conditions as
              each may be updated or modified from time to time in accordance
              with the provisions below and therein.
              <br /> <br />
              PICKMONK India Private Limited, may at its sole discretion:
              <br />
              Deny the right to access, restrict, suspend, or terminate any/or
              all access to anyone and/or players who we believe have violated
              any of the below Terms and Conditions;
              <br /> <br />
              Retain the right to change, suspend and/or discontinue all/any of
              the parts of the Platform Services provided;
              <br />
              Reject, remove, rescind, or move any/all material that may be
              submitted by the Player;
              <br />
              Move, Remove, and Delete any content that is available on its
              Platform;
              <br />
              Delete and/or De-activate a Player's account;
              <br />
              Delete and/or De-activate the information and/or files on any/all
              of the Player's account;
              <br />
              Retain the right to establish general practices and limits
              concerning the use of the Platform and the Services provided by
              PICKMONK India Private Limited; <br />
              Offer Discounts to its players in the form it deems fit
              (hereinafter referred to as "Bonus"). All such discounts
              shall be credited to the accounts of Players called Bonus
              Accounts;
              <br />
              Revise or make additions and/or Deletions to the category of the
              players available for the selection in a Contest on account of
              revisions to the category of players involved in the relevant
              Sporting Event;
              <br />
              Retain and assign its rights and liabilities to all the Player
              Accounts hereunder to any entity and post such assignment
              intimation on the accounts of the Player and shall also be sent to
              the registered Email – IDs of all the Players. <br />
              <br />
              By using this Service, the Players consent to receiving the
              communications such as announcements, administrative messages, and
              advertisements from PICKMONK.
              <br />
              <br />
              In the event any player breaches or PICKMONK reasonably believes
              that such player has breached these Terms and Conditions, or has
              illegally or improperly used the Website and/or Application and/or
              the Services on the PICKMONK Platform, PICKMONK may, at its sole
              and absolute discretion, and without any notice to the player,
              restrict, suspend, delete and/or terminate such player's access to
              all of any part of the PICKMONK Contests and PICKMONK Platform,
              deactivate and/or delete the account of the player and all the
              related content, information on the account, posted by the Player
              and further take technical and legal steps as it deems necessary.
              <br />
              <br />
              If PICKMONK charges its Players a platform and/or game fee in
              respect of any of the services provided on its platform, PICKMONK
              shall, without delay, repay such platform fee in the event of
              suspension or removal of the Player's Account from its Platform or
              on account of any negligence or deficiency on the part of
              PICKMONK, but not if such suspension or removal is effected due
              to:
              <br />
              <br />
              Any breach or inadequate performance by the Player on any of the
              herein-mentioned Terms and Conditions; or <br />
              Any circumstances beyond the reasonable control of PICKMONK.{" "}
              <br />
              <br />
              <h3 className="content_box_green_heading">License</h3>
              The Platform and its Services are controlled and operated by
              PICKMONK India Private Limited (401, Silver Radiance, Sindhu
              Bhavan Marg, New Pakwan Cross Road, Thaltej, Ahmedabad – 380 059,
              Gujarat, India).
              <br />
              <br />
              Subject to the Terms and Conditions contained herein, PICKMONK
              grants the Player a non–exclusive, non – transferable right to
              install and use the Software on the PC or Mobile Device, as the
              case may be, in order to access PICKMONK Platform and its
              Services.
              <br />
              <br />
              The Software is licensed to the Player by PICKMONK India Private
              Limited for the Player's private and personal use. However, the
              present platform and services are not eligible for:
              <br />
              <br />
              Players under 18 years of age; <br />
              Players under the legal age of majority in their jurisdiction; and
              <br />
              Players connecting to the Platform from Excluded Territories from
              which it is illegal to do so;
              <br />
              Players residing outside of India; <br />
              Players not having a valid Email Address; <br />
              PICKMONK reserves the right at any time to request its players to
              provide evidence of age to ensure that minors are not using the
              PICKMONK Platform and services. PICKMONK further reserves the
              right to suspend and/or cancel the account and exclude the
              Player(s), temporarily and/or permanently, from using the
              Platform, if satisfactory proof of age is not provided.
              <br />
              <br />
              <h3 className="content_box_green_heading">
                Intellectual Property Rights
              </h3>
              PICKMONK includes a combination of content created by its
              partners, affiliates, licensors, associates, and/or players and
              PICKMONK is the sole holder of all the rights in the Software and
              the Software's code, structure and organization, including the
              Trade Mark, Service marks, Trade names and/or Domain names,
              Copyright, Trade Secrets, Proprietary rights and any other rights
              (hereinafter referred to as "Intellectual Property Rights").
              <br />
              <br />
              The intellectual property rights, all the materials on its Website
              and Mobile Software, domain names, including but not limited to
              text, software, images, illustrations, audio clips and video
              clips, games, contests, advertisements, written content,
              illustrations, marks, logo, and flash animations, is owned by
              PICKMONK, its partners, licensors and/or associates and is
              protected by copyright and/or other intellectual property or other
              rights.
              <br /> <br />
              The Players may not modify, publish, transmit, participate in the
              transfer and/or sale of, reproduce, create derivative works of,
              distribute, publicly perform, publicly display, or in any way
              exploit any of the materials or content on the Platform, either in
              whole or in part, as the same is prohibited
              <br /> <br />
              Without the prior consent and license from PICKMONK the
              modification of the materials, use of any of the material on any
              other website, application, or networked computer environment, or
              use of any of the material and services from the PICKMONK Platform
              for any purpose other than personal, non – commercial use by the
              Player, is a violation of the Intellectual Property Rights and
              other proprietary rights, and the same is prohibited.
              <br /> <br />
              Players are solely responsible for the materials posted publically
              or transmitted privately via post, E-mail, Transmit, or otherwise
              make available on the PICKMONK Platform (hereinafter referred to
              as "Player's Content").
              <br /> <br />
              Each player shall represent and warrant that he/she shall own all
              Intellectual Property Rights in the Player's Content and that no
              part of the Player's Content infringes any third-party rights.
              <br /> <br />
              The Player further confirms and undertakes to not display or use
              any of the names, logos, marks, labels, trademarks, copyright or
              intellectual property, and proprietary rights of any third party
              on the PICKMONK Platform.
              <br /> <br />
              The Player agrees to indemnify and hold PICKMONK, its director,
              employees, affiliates, licensees, and/or licensors harmless and
              assigns all costs, damages, loss, and harm including towards
              litigation costs and counsel fees, in respect of any third party
              claims, that may be initiated including for infringement of
              Intellectual Property Rights arising out of such display or use of
              the names, logos, marks, labels, trademarks, copyrights,
              proprietary rights or intellectual property rights on PICKMONK
              Platform, by such Player or through the Player's commissions or
              omissions.
              <br /> <br />
              The Player agrees to be solely liable for any damage, cost, or
              expenses arising out of or in connection with the commission of
              use of any unauthorized use.
              <br /> <br />
              The Player agrees to notify PICKMONK immediately upon becoming
              aware of the commission by any person(s) of any unauthorized use
              and shall provide PICKMONK with reasonable assistance with any
              investigations it conducts in light of the information provided by
              the Player, in this respect
              <br /> <br />
              The Players hereby grant to PICKMONK and its directors,
              affiliates, licensors, and associates a worldwide, irrevocable,
              royalty-free, non–exclusive, sub-licensable license to use,
              reproduce, create derivative works of, distribute, publicly
              perform, publicly display, transfer, transmit, and/or publish
              player's content for any of the following purposes: for displaying
              it on the PICKMONK Platform; for distributing player's content,
              either electronically or via any other online and/or offline
              media, to other players seeking to download or otherwise acquire
              it; and/or for storing the player's content in a remote database
              accessible by end users, for a charge.
              <br /> <br />
              The Players grant that this license shall apply to the
              distribution and the storage of Player's Content in any form,
              medium, or technology.
              <br /> <br />
              In the event of unauthorized use of the intellectual property and
              proprietary rights, the correct course of action will be taken
              including, but not limited to, account cancellation with all
              account funds being retained by PICKMONK.
              <br /> <br />
              All names, logos, marks, labels, intellectual property rights, and
              proprietary rights on PICKMONK Platform(s) belonging to any
              person, including the player, entity, and/or third party(s) are
              recognized as proprietary to the respective owners and any claims,
              controversy or issues against these names, marks, logos, labels,
              intellectual property rights, and proprietary rights must be
              directly addressed to the respective parties under notice to
              PICKMONK.
              <br /> <br />
              <h3 className="content_box_green_heading">
                Third-Party Sites, Services, and Products
              </h3>
              PICKMONK may contain links to other third-party websites
              (hereinafter referred to as "Linked Sites") and operated by third
              parties. Players' use of each of these sites is subject to the
              conditions, if any, posted by the sites. PICKMONK does not
              exercise control over any Internet sites apart from PICKMONK and
              cannot be held responsible for any content residing in any
              third–party internet site.
              <br /> <br />
              PICKMONK's inclusion of third-party content or links to
              third–party internet sites is not an endorsement by PICKMONK of
              such third–party Internet sites.
              <br /> <br />
              The player's correspondence, transaction/offers, or any other
              related activities with third parties, including payment providers
              and verification service providers, is solely between the Player
              and the concerned third party. The Player's correspondence,
              transaction, and usage of the services/offers of such third-party
              sites shall be subject to the terms and conditions, policies, and
              other service terms adopted/implemented by such third-party sites,
              and the Player shall be solely responsible for reviewing the same
              before transacting or availing of the services and/or offers of
              such third party internet sites. Any questions, complaints or
              claims related to any such third-party sites, services, or vendor
              should be directed to the concerned appropriate vendor.
              <br /> <br />
              PICKMONK Platform and Services contain content that is created by
              PICKMONK. It does not guarantee the accuracy, integrity, or
              quality of the content provided by third-party websites and such
              content may not be relied upon by the Players in utilizing the
              services provided on the PICKMONK Platform, including while
              participating in any of the contests hosted on PICKMONK Platform.
              <br /> <br />
              <h3 className="content_box_green_heading">Submissions </h3> <br />
              Any E-mails, Notes, Messages, Billboards, Ideas, Suggestions,
              Concepts, or other material submitted on the PICKMONK Platform
              through the Website will be the Intellectual Property of PICKMONK
              India Private Limited.
              <br /> <br />
              The Company shall be entitled, consistent with its policies
              regarding privacy, to use the material or any of its elements for
              any type of use without limitation, including any media, whether
              now known or hereafter devised. Whenever a Player or any other
              authorized representative of the Player, submits material on the
              website, application, and/or any other platform of PICKMONK, the
              Player involuntarily agrees and consents that PICKMONK India
              Private Limited has the right to publish or otherwise use the
              material or any of its elements for any type of use, including
              promotional and advertising purposes, subject to the company's
              privacy policy.
              <br /> <br />
              By using the website, application, and/or any other Platform of
              PICKMONK, the Player consents to not submitting or otherwise
              publishing any content which:
              <br />
              Libels, defames, invades the privacy of player(s) and/or third
              parties;
              <br />
              Obscene, pornographic, abusive, or threatening to the privacy of
              player(s) and/or third parties;
              <br />
              Infringes any Intellectual Property or other rights of any entity
              or person, including, but not limited to infringing anyone's
              copyright, trademark, intellectual property right, or proprietary
              right;
              <br />
              Violates any central/state law;
              <br />
              Advocates any illegal activity; or
              <br />
              Advertises or otherwise solicits funds or is a solicitation for
              goods or services.
              <br /> <br />
              Several deceptive emails, websites, blogs, etc, claiming to be
              from or associated with PICKMONK may circulate on the Internet.
              These Emails, Websites, Blogs, etc often include the logo, photos,
              links, content, or other information. Some Emails, websites,
              blogs, etc may call the Players requesting to provide login name,
              password, etc, or inform the Player that the Player has won a
              grand prize/gift or provide a method to commit an
              illegal/unauthorized act or deed or request detailed personal
              information or a payment of some kind. PICKMONK India Private
              Limited is in no way associated with such contents and sources and
              its accompanying materials and PICKMONK strongly recommends to not
              use, respond or communicate or open such Emails, Websites, Blogs,
              etc.
              <br /> <br />
              The Player agrees to indemnify PICKMONK India Private Limited and
              its directors, officers, employees, agents, distributors, and
              affiliates from and against any and all third-party claims,
              demands, liabilities, costs, and/or expenses, including legal
              fees, resulting from the player's breach of any of the foregoing
              agreements, representation, and warranties.
              <br /> <br />
              PICKMONK may use the information provided by the Players,
              including your Email Address or Phone Number, to contact the
              Player about the Services availed by the Player or to update the
              Player(s) on any of its Updated Services, if any.
              <br /> <br />
              <h3 className="content_box_green_heading">Privacy Policy</h3>
              All information collected on the Application and/or Platform by
              PICKMONK from the Players, such as registration and credit card
              information, is subject to PICKMONK's Privacy Policy.
              <br />
              <br />
              By using any part of the PICKMONK Platform, the Player consents to
              the collection, use, disclosure, and transfer of their personal
              information for the purposes outlined in the Privacy Policy and
              Terms and Conditions, and to the collection, processing, and
              maintenance of this information. If the player does not agree with
              the Privacy Policy, it is requested to not use the PICKMONK
              Platform and its Services.
              <br /> <br />
              The Player using any part and portion of the PICKMONK Platform and
              Services consents to the collection, use, and disclosure of the
              personal information in accordance with the Privacy Policy.
              However, it will be upon the Player to provide PICKMONK with
              certain information, and/or withdraw his/her consent to collect
              certain information and/or even request temporary suspension of
              the collection of information, and/or request deletion of
              information collected, please note that in such an event, the
              Player may not be able to take advantage of the entire scope of
              the feature and services offered by PICKMONK Platform and PICKMONK
              reserves its right to not provide its services to their Player(s).
              <br /> <br />
              To avail of the services on the PICKMONK Platform, the Player will
              be required to provide the following information for the
              registration process namely:
              <br />
              Username
              <br />
              Password <br />
              Email Address <br />
              Date of Birth
              <br /> <br />
              In the course of providing the Player access to the features, and
              services on the PICKMONK Platform, the Player may have to provide
              their identity and in securing the account details, may have to
              permit the PICKMONK Platform to capture, record their device
              information, operating system information, network information,
              and location information. The Player may also be required to
              furnish additional information, including the Aadhar Card and/or
              Permanent Account Number.
              <br /> <br />
              The Player consents that in certain instances, the PICKMONK
              Platform may also have to collect Sensitive Personal Information
              from the Player on its Platform. Except for any financial
              information that the Player chooses to provide while making
              payment for any kind of Services on the Platform, PICKMONK does
              not collect any other Sensitive Personal Information in the course
              of providing the Services.
              <br /> <br />
              Any Information collected by PICKMONK shall not be disclosed to
              any third party without the Player's express consent, save as
              otherwise set out in the Terms and Conditions or as provided in a
              separate written agreement or as required by law. However, it is
              clarified that this condition shall not be applicable to publicly
              available information, including Sensitive Personal Information,
              in relation to the Player on the Platform.
              <br /> <br />
              In the course of providing the Services, the Players may invite
              other existing Players or other Players (hereinafter referred to
              as "Referral Players" and/or "Invited Players") to participate in
              any of the Services by providing their E-mail Address or Username
              of such Players. PICKMONK may thereafter use this information to
              contact the Referral Player and invite such Player to register
              with PICKMONK (if the Referral Player is not an existing Player)
              and participate in the Game in relation to which such person was
              invited by the Player. It is to be clarified that the
              participation of the Referral Player in any of the Contests shall
              be subject to the conditions mentioned herein for the use of the
              Platform as well as the terms stated in the Privacy Policy.
              <br /> <br />
              The Player hereby agrees that the Referral Player have consented
              and agreed to such disclosure to and use of their E-mail Address
              and Username by PICKMONK Platform.
              <br /> <br />
              All required information is specific and based on the kind of
              Contest/Game/Service the Player wishes to participate in or
              access, and will be utilized for the purpose of providing
              services, including but not limited to the Services requested by
              the Player. The information supplied by the Player enables the
              PICKMONK Platform to improve the Services and provide a
              player–friendly game experience.
              <br /> <br />
              PICKMONK may also share such information with affiliates and third
              parties in limited circumstances, including for the purpose of
              providing services requested by the Player, complying with the
              legal process, preventing fraud or imminent harm, and ensuring the
              security of the PICKMONK Platform, Services and Network.
              <br /> <br />
              PICKMONK may also share information as provided by the Player and
              the data concerning usage of the Services and Participation in the
              Contents with third-party service providers engaged by PICKMONK,
              for the purpose of data analytics or other similar purposes, for
              the purpose of storage and improving the PICKMONK services and the
              same will be provided to the third party services providers after
              notifying to the Players. The Players will also have the
              opportunity to withhold or withdraw their consent in providing the
              information listed herein.
              <br /> <br />
              By using the Platform, the Player hereby expresses to agree and
              grant consent to the collection, use, and storage of the
              Information by PICKMONK and PICKMONK reserves the right to share,
              disclose and transfer the information collected hereunder with its
              own affiliates. In the event PICKMONK sells, discloses, or
              transfers all or a portion of its business assets, consumer
              information or transfers the same as a part of any transaction,
              the Player hereby expressly grants consent and permission to
              PICKMONK for disclosure and transfer of such information to such
              third parties.
              <br /> <br />
              The Player expressly agrees and consents that PICKMONK may share
              information as provided by the Player and the data concerning
              usage of the Services and Participation in the Contests with its
              commercial partners for the purpose of facilitating player
              engagement, for marketing and promotional purposes, and other
              related purposes.
              <br /> <br />
              PICKMONK reserves the right to disclose personal information as
              obligated by law, in response to duly authorized legal processes,
              and governmental requests and as necessary to protect the rights
              and interests of PICKMONK India Private Limited.
              <br /> <br />
              PICKMONK may also include links to other third-party websites.
              Such third-party websites are governed by their respective
              policies, which are beyond PICKMONK's control. Once the Player
              leaves the PICKMONK's servers, use of any information provided by
              the Player shall be governed by the privacy policy and terms and
              conditions of the third-party operator and the third-party website
              the Player is visiting. That policy may differ from the policy of
              PICKMONK's policy and PICKMONK is not responsible for the privacy
              practices or the content of such third-party websites.
              <br /> <br />
              The Player's personal information may be retained and may continue
              to be used until the relevant purposes for the use of the Player's
              information as described herein are no longer applicable; and
              PICKMONK is no longer required by the applicable law, regulations,
              contractual obligations or legitimate business purposes to retain
              the personal information and the said retention is not required
              for the establishment, exercise or defense of any legal claim.
              <br /> <br />
              <h3 className="content_box_green_heading">Cookie Policy</h3>
              To improve the effectiveness and usability of the Platform for the
              Players, PICKMONK uses "cookies" and/or similar electronic tools
              to collect information.
              <br /> <br />
              In this Cookie Policy, any use of the word "you", "yours" or
              similar expressions shall mean any player and/or user of the
              Platform and terms such as "we", "us", "our" or similar
              expressions shall mean PICKMONK India Private Limited.
              <br /> <br />
              PICKMONK uses third-party scripts to gather the statistics for the
              Platform. These third parties use browser cookies for statistical
              analysis related to the Player's browsing behavior on the Website.
              The information collected by these third parties may include
              information such as the Player's web or application request,
              Internet Protocol (IP) Address, browser type, browser language,
              referring/exit pages and URLs, Platform type, number of clicks,
              domain names, landing pages, pages viewed and the order of those
              pages, the amount of time spent on particular pages, the date and
              time of the Player's request, and one or more cookies that may
              uniquely identify the Player's browser. The information stored and
              collected through the usage of these cookies will not be shared
              with any third party except for legitimate processing purposes,
              and such third parties shall be bound to protect the Player's
              privacy to the same extent as PICKMONK. The information stored and
              collected through the usage of these cookies will not be sold to
              any third-party site for profit.
              <br /> <br />
              The Player can manage the cookies by activating the setting on
              their Internet browser that allows the Player to refuse the
              setting of all or some cookies. If the Player decides to turn the
              cookies off, this will limit the service that PICKMONK can provide
              and may affect the player's experience. The Terms and Conditions,
              Policy, and Procedures are under continual review and PICKMONK
              may, from time to time, update its cookie policy. Any such changes
              will be posted and notified to the Player.
              <br /> <br />
              By continuing to access the website, the User gives their consent
              to the usage of the cookies.
              <br /> <br />
              <h3 className="content_box_green_heading">Security Procedure</h3>
              All the information gathered on PICKMONK's Platform shall be
              securely stored within PICKMONK's controlled database. The said
              database is stored on servers secured behind a firewall to which
              the access is password protected and strictly limited on a
              need-to-know basis. However, PICKMONK understands that as
              effective the measures may be, no security system is impenetrable.
              Thus, PICKMONK cannot guarantee the security of the database, nor
              guarantees that the information so supplied by the Player will not
              be intercepted while being transmitted on the PICKMONK Platform
              over the internet. Furthermore, any information the Player
              provides by posting on the discussion forums will be available to
              anyone with Internet access. By using the Platform, the Player
              understands and agrees that the information may be transferred or
              used by other players and/or users residing in and/or outside of
              India.
              <br /> <br />
              PICKMONK does not warrant that its Platform, servers, or Email
              sent by PICKMONK or on its behalf are virus free. PICKMONK will
              not be liable for any damage of any kind arising from the use of
              this Platform, including, but not limited to compensatory, direct,
              indirect, incidental, punitive, special, and consequential
              damages, loss of data, goodwill, business opportunity, income or
              profit, loss of or damage to property and claims of third parties.
              Furthermore, in no event, shall PICKMONK be liable for any damages
              whatsoever in an amount in excess of INR 100.
              <br /> <br />
              <h3 className="content_box_green_heading">Player Conduct</h3>
              The Players agree to abide by these Terms and Conditions and all
              other rules, regulations, and terms of use of the Website.
              <br /> <br />
              In the event, the player does not abide by these Terms and
              Conditions and all other rules, regulations, and terms of use,
              PICKMONK may, at its sole and absolute discretion, take necessary
              remedial action, including but not limited to:
              <br /> <br />
              Restricting, suspending, or terminating any Player's access to all
              or any part of PICKMONK Services;
              <br /> <br />
              Deactivating or deleting a Player's account and related
              information and filing on the account. Any amount remaining unused
              in the Player's Contest Account or Winnings Account on the date of
              deactivation or deletion of the account shall be transferred to
              the Player's Bank Account available on PICKMONK's Platform subject
              to the processing fee, if any, applicable on such transfers as set
              out herein; or
              <br />
              Refraining from awarding any prize(s) to such Player.
              <br /> <br />
              The player agrees to provide true, accurate, current, and complete
              information at the time of the registration and at all times as
              required by PICKMONK
              <br /> <br />
              Player agrees to update and keep their required information
              updated at all times
              <br /> <br />
              The player shall not register or operate more than one Player
              Account with PICKMONK
              <br /> <br />
              Players agree to ensure that they can receive all communication
              from PICKMONK by marking E-mails or sending SMSs from PICKMONK as
              part of their "safe senders" list.
              <br /> <br />
              PICKMONK shall not be held liable if any E-mail/SMS remains unread
              by a Player as a result of such e-mail getting delivered to the
              Player's junk or spam folder.
              <br /> <br />
              Any password issued by PICKMONK to a Player may not be revealed to
              anyone else. Players may not use anyone else's password. Players
              are responsible for maintaining the confidentiality of their
              accounts and passwords. Players agree to immediately notify
              PICKMONK of any unauthorized use of their passwords or accounts or
              any other breach of security.
              <br /> <br />
              Players agree to exit/log out of their accounts at the end of each
              contest. PICKMONK shall not be responsible for any loss or damage
              that may result if the Player fails to comply with these
              requirements.
              <br /> <br />
              The player agrees not to use cheats, exploits, automation,
              software, bots, hacks or any unauthorized third-party software
              designed to modify or interfere with the PICKMONK Platform and/or
              Services and/or experience or assist in such activity.
              <br /> <br />
              The player agrees not to copy, modify, rent, lease, loan, sell,
              assign, distribute, reverse engineer, grant a security interest
              in, or otherwise transfer any right to the technology or software
              underlying the PICKMONK Platform and/or its Services.
              <br /> <br />
              Player agrees that without PICKMONK's express written consent,
              none of the Player shall modify or cause to be modified any files
              or software that are part of PICKMONK's Platform and/or Services.
              <br /> <br />
              The player agrees not to disrupt, overburden, and/or aid and/or
              assist in the disruption or overburdening of any computer or
              server used to offer or support PICKMONK's Platform or its
              Services; or the enjoyment of PICKMONK Services by any other
              player or person.
              <br /> <br />
              The player agrees not to institute, assist or become involved in
              any type of attack, including without limitation distribution of a
              virus, denial of service, or other attempts to disrupt PICKMONK
              Services or any other person's use or enjoyment of PICKMONK
              Services.
              <br /> <br />
              The player shall not attempt to gain unauthorized access to the
              User accounts, Servers, or networks connected to the PICKMONK
              Platform by any means other than the User interface provided by
              PICKMONK, including but not limited to, by circumventing or
              modifying, attempting to circumvent or modify, or encouraging or
              assisting any other person to circumvent or modify, any security,
              technology, device, or software that underlies or is part of
              PICKMONK Platform.
              <br /> <br />
              PICKMONK may on receipt of information ban a person from
              participation and/or withdrawal of their winnings if such player
              is found to be the one with insider knowledge of participating
              teams in any given contest/match, organizing boards, leagues, etc.
              <br /> <br />
              The player shall not publish any content that is patently false
              and untrue and is written or published in any form, with the
              intent to mislead or harass a person, entity, or agency for
              financial gain or to cause any injury to any person.
              <br /> <br />
              If a Player chooses a username that, in PICKMONK's considered
              opinion is obscene, indecent, abusive or that might subject to
              public disparagement or scorn, or a name which is an official
              team/league/franchise name and/or name of any sporting
              personality, as the case may be, PICKMONK reserves the right,
              without prior notice to the Player, to restrict usage of such
              names, which in PICKMONK's opinion fall within any of the said
              categories and/or change the username and intimate the Player or
              delete such username and posts from PICKMONK Platform, deny such
              Player access to its Services, or any combination of these
              options.
              <br /> <br />
              Unauthorized access to the PICKMONK Platform is a breach of these
              Terms and Conditions, and a violation of the law.
              <br /> <br />
              The player agrees not to access the PICKMONK Platform by any means
              other than through the interface that is provided by PICKMONK for
              use in accessing its website and/or application.
              <br /> <br />
              The Player agrees not to use any automated means, including,
              without limitation, agents, robots, scripts, or spiders, to
              access, monitor, or copy any part of our sites, except those
              automated means that we have approved in advance and in writing.
              <br /> <br />
              The use of PICKMONK is subject to existing laws and legal
              processes. Nothing contained in these Terms and Conditions shall
              limit PICKMONK's right to comply with governmental, court, and
              law-enforcement requests or requirements relating to the player's
              use of the PICKMONK Platform.
              <br /> <br />
              Without limiting the foregoing, the Players agree not to use the
              PICKMONK Platform for any of the following:
              <br /> <br />
              To engage in any obscene, offensive, indecent, racial, communal,
              anti-national, objectionable, defamatory, or abusive action or
              communication;
              <br />
              To harass, stalk, threaten, or otherwise violate any legal rights
              of other individuals;
              <br /> <br />
              To publish, post, upload, e-mail, distribute, or disseminate
              and/or collectively transmit any inappropriate, profane,
              defamatory, infringing, obscene, indecent, or unlawful content;
              <br /> <br />
              To Transmit files that contain viruses, corrupted files, or any
              other similar software or programs that may damage or adversely
              affect the operation of another person's computer, PICKMONK
              Platform, any software, hardware, or telecommunications equipment;
              <br /> <br />
              To advertise, offer or sell any goods or services for any
              commercial purpose on the PICKMONK Platform without the express
              written consent of PICKMONK India Private Limited;
              <br /> <br />
              To download any file, recompile or disassemble or otherwise affect
              our products that you know or reasonably should know cannot be
              legally obtained in such manner;
              <br /> <br />
              To falsify or delete any author attributions, legal or other
              proper notices or proprietary designations, or labels of the
              origin or the source of software or other material;
              <br /> <br />
              To restrict or inhibit any other player from using and enjoying
              any public area within our sites;
              <br /> <br />
              To collect or store personal information about other Players;
              <br /> <br />
              To interfere with or disrupt the PICKMONK Platform, servers, or
              networks;
              <br /> <br />
              To impersonate any person or entity, including, but not limited
              to, a representative and/or affiliate of PICKMONK, or falsely
              state or otherwise misrepresent the Player's affiliation with a
              person or entity;
              <br /> <br />
              To forge headers or manipulate identifiers or other data in order
              to disguise the origin of any content transmitted through
              PICKMONK's Platform or to manipulate the Player's presence on
              PICKMONK Platform;
              <br /> <br />
              To take any action that imposes an unreasonably or
              disproportionately large load on our infrastructure;
              <br /> <br />
              To engage in any illegal activities.
              <br /> <br />
              The Player agrees to use the PICKMONK's bulletin board services,
              chat areas, news groups, forums, communities, and/or message or
              communication facilities (collectively, the "Forums") only to send
              and receive messages and material that are proper and related to
              that particular Forum.
              <br /> <br />
              To engage in any activity that threatens the unity, integrity,
              defense, security, or sovereignty of India, friendly relations
              with foreign States, or public order, or causes incitement to the
              commission of any cognizable offense or prevents investigation of
              any offense or is insulting other nation.
              <br /> <br />
              The Player may reach out to PICKMONK through its Helpdesk if the
              player has any concerns concerning a game and/or contest within
              Forty-Eight (48) hours of the winner declaration for the concerned
              contest.
              <br /> <br />
              Persons below the age of eighteen (18) years are not allowed to
              participate in any of the contests, or games (by whatever name
              called) on the PICKMONK Platform. The Players will have to
              disclose their real age at the time of getting access to the
              PICKMONK Platform.
              <br /> <br />
              PICKMONK may not be held responsible for any content contributed
              by Players on its Platform.
              <br /> <br />
              <h3 className="content_box_green_heading">
                Registration in a Contest
              </h3>
              To register for the contest(s), the Players are required to
              accurately provide the following information:
              <br /> <br />
              Full Name; <br />
              Team Name(s);
              <br />
              E-mail Address;
              <br />
              Password;
              <br />
              User Name;
              <br />
              State of Residence;
              <br />
              Gender;
              <br />
              Date of Birth
              <br /> <br />
              Players are required to confirm that they have read and shall
              abide by the Terms and Conditions
              <br /> <br />
              In the event a Player indicates, while entering an address, that
              he/she is a resident and/or residing in any of the Excluded
              Territories, such Player will not be permitted to proceed to sign
              up for any match in the paid version of the Contest as described
              <br /> <br />
              Once the Player has entered the above information and clicked on
              the "register" tab, and such Players are above the age of 18
              years, they will be sent an email confirming their registration
              and containing their login information
              <br /> <br />
              It will solely be the Player's responsibility to keep their
              password confidential. PICKMONK shall not be responsible in any
              way if the user account and password are logged in and/or accessed
              by an unauthorized person.
              <br /> <br />
              <h3 className="content_box_green_heading">
                Conditions for Participating in a Contest{" "}
              </h3>
              By entering a Contest, the Player agrees to be bound by the Terms
              and the decisions mentioned herein.
              <br /> <br />
              Subject to the terms and conditions stipulated herein, PICKMONK
              India Private Limited, at its sole discretion, may disqualify any
              Player from a Contest, refuse to award benefits or prizes, and
              require the return of any prizes, if the Player engages in unfair
              conduct, which PICKMONK deems to be improper, unfair or otherwise
              adverse to the operation of the Contest or is in any way
              detrimental to other players which include, but is not limited to:
              <br /> <br />
              Falsifying one's personal information (including, but not limited
              to, Name, E-mail address, Bank account details, and/or any other
              information or documentation as may be requested by PICKMONK
              Platform to enter a contest and/or claim a prize/winning;
              <br /> <br />
              Engaging in any type of financial fraud or misrepresentation
              including unauthorized use of credit/debit instruments, payment
              wallet accounts, etc. to enter a Contest or claim a prize. It is
              expressly clarified that the onus to prove otherwise shall solely
              lie on the player;
              <br /> <br />
              Colluding with any other player(s) or engaging in any type of
              syndicate play;
              <br /> <br />
              Any violation of Contest rules or the Terms of Use;
              <br /> <br />
              Accumulating points or prizes through unauthorized methods such as
              automated bots, or other automated means;
              <br /> <br />
              Using automated means (including but not limited to harvesting
              bots, robots, parsers, spiders, or screen scrapers) to obtain,
              collect or access any information on the Website or of any Player
              for any purpose;
              <br /> <br />
              Any type of Bonus misuse, misuse of the Referral Friends
              program, or misuse of any other offers or promotions;
              <br /> <br />
              Tampering with the administration of a Contest or trying to in any
              way tamper with the computer programs or any security measure
              associated with a Contest;
              <br /> <br />
              Obtaining other players' information without their express consent
              and/or knowledge and/or spamming other players; or <br />
              Abusing the Website in any way including but not limited to
              unparliamentary language, slang, or disrespectful words. <br />
              <br />
              It is clarified that in case a Player is found to be in violation
              of its terms and conditions, especially Conditions of
              Participation in a Contest, PICKMONK reserves its right to
              initiate appropriate Civil/Criminal remedies as it may be advised
              other than forfeiture and/or recovery of prize from the
              Player if any.
              <br /> <br />
              <h3 className="content_box_green_heading">
                Participation, Contest(s) and Prizes
              </h3>
              As part of the Platform Services, PICKMONK may make available the
              contest(s) on its PICKMONK Platform
              <br /> <br />
              Players agree and consent that, only those players who have
              successfully registered on the PICKMONK Platform as well as
              registered prior to each match in accordance with the procedure
              outlined herein, shall be eligible to participate in the Contest
              and win prizes.
              <br /> <br />
              PICKMONK has currently made available only the Cricket Fantasy
              Sports Game. It will make available the following contests and
              games on its platform at a later date: Fantasy Football, Baseball,
              Basketball, and Kabaddi Games.
              <br /> <br />
              Individuals (s) wishing to participate in Fantasy Cricket Sports
              Game contest(s) are invited to create their Fantasy Team(s),
              consisting of real-life cricketers involved in the real–life
              cricket match, series, or tournament, to which the Fantasy Game
              relates. PICKMONK offers its platform to Players for Fantasy Game
              Contest(s) being created and Players can participate in the
              contest(s) with their teams
              <br /> <br />
              Depending upon the circumstances of each match, the Players can
              edit their teams till the official match start time as declared by
              the officials of the Sports Event or adjusted deadline, as
              specified below:
              <br /> <br />
              PICKMONK reserves the right to abandon a specific round or adjust
              the deadline of a round in certain specific, uncertain scenarios,
              which are beyond PICKMONK's reasonable control, including but not
              limited to the ones mentioned herein below:
              <br /> <br />
              The actual match start time is before the official deadline:
              PICKMONK reserves the right to adjust the deadline by a maximum of
              10 minutes or 3 overs bowled, whichever is less, before the
              official match start time
              <br /> <br />
              In the case where the official match time cannot be verified by
              PICKMONK through reliable and/or publicly available sources,
              PICKMONK reserves the right to adjust the deadline to such a time
              by which a maximum of 3 overs in the given time are bowled.
              <br /> <br />
              The actual match start time is after the official deadline: <br />
              PICKMONK reserves the right to extend the deadline or abandon the
              contest/game based on the circumstances such as delayed toss,
              interruption on account of weather, non-appearance of teams,
              technical/equipment/platform glitches causing delays, etc.
              <br /> <br />
              PICKMONK shall endeavor to send communications through Emails
              and/or SMS Communication and/or notify and update the player about
              any such change as is contemplated in the aforementioned
              paragraphs
              <br /> <br />
              The player has to select a match from the Tournament and/or Series
              which is scheduled as per the Cricket Calendar. The Player may
              pick a team consisting of at least as many players as required to
              constitute a real-world team to score points for the duration of
              at least one entire real–world match.
              <br /> <br />
              Players join a contest for a particular match and create their
              team of 11 players (these 11 players could be batsmen, bowlers, All
              Rounders, Wicker Keeper).
               {/* A total of 25 available players are
              displayed in a 5X5 Matrix. */}
              <br /> <br />
              the available players are divided into 5 different categories
              (A, B, C, D, and E). The categories are based on players' past
              performances and each category will have players.
              <br /> <br />
              The user is allotted 15 total salary points/credits. All the
              players in Category A will have 5 salary points/credits, All the
              players in Category B will have 4 salary points/credits, All the
              players in Category C will have 3 salary points/credits, All the
              players in Category D will have 2 salary points/credits, and All
              the players in Category E will have 1 salary point/credit.
              <br /> <br />
              The user needs to select a total of 11 players within the allotted
              salary points/credits and select 1 (one) Super Selection and 1
              (one) Bonus Player from the 11 selected players. Once the
              selections are completed, click the "Create Team" button to create
              your team.
              <br /> <br />
              The Contest(s) across the PICKMONK Platform shall, in addition to
              the Terms and Conditions, rules and regulations mentioned herein,
              be governed by the Fantasy Rules and Fantasy Point System
              available on the PICKMONK's Website and/or Application and other
              rules and regulations, including the rules and regulations in
              relation to any payments made to participate in the Contest(s);
              and Players agree to abide by the same.
              <br /> <br />
              The Player may select from multiple running contests on PICKMONK.
              The Player may choose from a variety of contests.
              The Players may participate in the relevant contest(s) by paying
              the pre–designated fees as provided on the relevant Contest page.
              <br /> <br />
              The Player with the highest aggregate points at the end of the
              predetermined match shall be eligible to win a predetermined prize
              which is disbursed out of the prize  pool, as stated on the
              relevant Contest(s) page.
              <br /> <br />
              The Player may create different Teams for participation in
              Contests in relation to Sports Events across the PICKMONK
              Platform. However, unless PICKMONK specifies otherwise in relation
              to any Contest(s), players acknowledge and agree that they may
              enter only one team in any contest offered in relation to a Sports
              Event. In the case of Multiple Entry contests, a Player may enter
              more than one Team in a single Multiple Entry Contest. In
              addition, it is expressly clarified that PICKMONK may, from time
              to time, restrict the maximum number of Teams that may be created
              by a single user account or which a single user account may enter
              in a particular multiple-entry contest, in each case to such
              number as determined by PICKMONK at its sole discretion.
              <br /> <br />
              Participants shall pay a pre-designated fee for participating in
              the contest(s) being created on PICKMONK's platform.
              <br /> <br />
              In the event a Player indicates, while entering an address, that
              he/she is a resident of Excluded Territories, such Player will not
              be permitted to proceed to sign up for the match or contest and
              may not participate in any paid version of the Contest(s).
              <br /> <br />
              In two members and above public contests, where all participants
              have entered the contest with the exact same teams, including the
              captain and vice-captain, the contest prize shall be equally
              divided amongst all participants and the amount shall be deposited
              in the PICKMONK's winning account of all participants and the
              remaining amount shall be credited in the Bonus account of
              such participants.
              <br /> <br />
              In the event a Player is found to be violating this policy,
              PICKMONK India Private Limited, reserves the right to initiate
              appropriate action against such users as it deems fit, which shall
              also include forfeiture of the  Bonus Amount
              <br /> <br />
              In Starting Lineup Feature: The purpose of indicating a player's
              inclusion in the final starting lineup is only to provide
              information and assist a user in selecting his/her team on the
              PICKMONK Platform. The Player's inclusion in starting lineup is
              given by PICKMONK on the basis of information/data received
              through feed providers, publicly available information. The
              Players are advised to do thorough research of their own from
              official sources and/or other available sources of information.
              PICKMONK, shall not take any liability if a player earlier
              indicated as 'Playing' does not play or start for any reason
              whatsoever.
              <br /> <br />
              PICKMONK offers three formats of contests on its platform: <br />
              Practice Contests where the Players can without any restriction on
              participation, play free contests to improve their skills;
              <br />
              Players can win prize from various
              contests listed on the PICKMONK Platform;
              <br />
              Private Contest where the Players can invite specific players into
              a contest and restrict the participation to such invited players
              only.
              <br /> <br />
              <h3 className="content_box_green_heading">Paid contests</h3>
              In the Paid contest format, the Platform may make available the
              pre-designated number of Players and the winner will be
              determinable at the end of the match as per the rule of the
              contest
              <br />
              The number of Participants required to make the contest(s)
              operational will be pre-specified and once the number of Players
              in such contest(s) equals the pre–specified number required for
              that contest, such contest(s) shall be operational; in the case,
              the number of prerequisite players is less than the pre-specified
              number at the time of commencement of the match, such Contest(s)
              will not be operational and the pre-designated fee and the amount
              paid by each Player shall be returned to the account of such
              Player without any charge or deduction <br /> <br />
              <h3 className="content_box_green_heading">Private Contest</h3>
              In the Private Contest format, the PICKMONK Platform enables the
              players to create a contest and invite other players, whether
              existing or otherwise (termed as "Referral Player" and/or "Invited
              Player"), to create Teams and participate in the Contest(s).{" "}
              <br />
              The Player may create a Private Contest consisting of a
              pre–specified number of Players. The Player creating the Private
              Contest shall pay the pre–designated fee for such Private Contest
              and thereby join that private contest and shall supply a name for
              the Private Contest and be provided with a unique identification
              code, which will be issued to the account of such Player. The
              Player agrees and understands that once the Private Contest is
              created, no change shall be permitted in the terms or constitution
              of the Private Contest, except for a change in the name of the
              contest. The Player creating the private contest shall provide the
              PICKMONK Platform with the Email Address, Contact Number, or the
              Facebook Account Username of the Referral Player to enable
              PICKMONK to send a message or mail inviting such Referral Player
              to register with PICKMONK, if necessary, and participate in the
              Private Contest in relation to which the invite has been issued.
              <br />
              <br />
              To participate in the Private contest, an Invited User shall input
              the unique identification code associated with the Private Contest
              and pay the pre–designated fee for the Private Contest. Once the
              number of Participants in a private contest equals the number of
              pre–specified Players for that Private Contest, the Private
              Contest shall be rendered operative and no other Referral Player
              or Participant shall be permitted to participate in the Private
              Contest. In the event that any Private contest does not contain
              the pre–specified number of Players for that Private contest
              within 1 hour prior to the commencement of the match/contest, the
              Platform will initiate an automatic refund of the amount deposited
              by the Player. Such refund shall be processed after the expiry of
              the deadline for filing of the Players for such Private Contest.
              <br /> <br />
              It is being clarified that the participation of Referral Players
              in any Private Contest is subject to the pre–specified number of
              Players for that Private Contest and PICKMONK shall not be liable
              to any person for the inability of any Referral Player to
              participate in any private contest due to any cause whatsoever,
              including without limitation due to a hardware or technical
              malfunction or lack of eligibility of such Referral Player to
              participate in the Contest(s).
              <br /> <br />
              It is further clarified that the participation of the Referral
              Players will be in accordance with the terms and conditions
              mentioned herein, and any Referral Player trying to register
              through the Email IDs and/or registration number belonging to the
              Excluded Territories, shall not be allowed to register and/or
              allowed to play and enter the private contest(s).
              <br /> <br />
              All rules applicable to contest(s) as set out herein shall be
              applicable to all the contest formats.
              <br /> <br />
              The Players by participating in such a contest(s) hereby authorize
              PICKMONK to appoint a third party/Trustee/Escrow Agent to manage
              the player funds on their behalf.
              <br /> <br />
              <h3 className="content_box_green_heading">Payment Terms</h3>
              In respect of any transactions entered on the PICKMONK Platform,
              including making payment to participate in the paid version of
              Contest(s), the Players agree to be bound by the following payment
              terms:
              <br /> <br />
              The payment of pre–designated fee by the Players to participate in
              the Contest(s) is inclusive of the pre–designated platform fee for
              access to the PICKMONK Services charged by PICKMONK and
              pre–determined participant's contribution towards the prize
              pool.
              <br /> <br />
              Subject to these Terms and Conditions, all amounts collected from
              the Player are held in separate non–interest earning bank
              accounts. The said accounts are operated by a third party
              appointed by PICKMONK in accordance with the terms and conditions
              mentioned herein. From these bank accounts, the payouts can be
              made to (a) Players (towards their withdrawals), (b) PICKMONK
              (towards its Platform Fees), and to (c) the Government (towards
              TDS on winnings amount). PICKMONK receives only its share of the
              platform fees through the said Escrow Agent
              <br />
              PICKMONK reserves the right to charge a Platform Fee, which would
              be specified and notified by PICKMONK on the Contest Page, being
              created on the PICKMONK Platform, prior to a Player's joining of
              such Contest. The Platform Fee, which is inclusive of applicable
              tax thereon, will be debited from the Player's account balance and
              PICKMONK shall issue an invoice for such debit to the Player.
              <br /> <br />
              The Player may participate in a Contest wherein the Player has to
              contribute a pre-specified contribution towards the Prize
              Pool of such Contest, which will be passed on to the Winner(s) of
              the Contest after the completion of the Contest as per the terms
              and conditions of such Contest. It is clarified that PICKMONK has
              no right or interest in this Prize Pool, and only acts as an
              intermediary engaged in collecting and distributing the Prize
               Pool in accordance with the Contest terms and conditions.
              The amount to be paid in by the Player towards the Prize
              Pool would also be debited from the Player's account balance
              maintained with the Trustee.
              <br /> <br />
              Any user availing of PICKMONK Services are provided with two
              categories of accounts for the processing and reconciliation of
              payments: (i) "Unutilized" Account, (ii) "Winnings" Account. It is
              clarified that in no instance the transfer of any amounts in the
              Player's accounts to any other category of account held by the
              user or any third party account, including a bank account held by
              a third party:
              <br /> <br />
              The player's winnings in any Contest will reflect as credits to
              the Player's Winnings Account;
              <br />
              The player's remitting the amount through the designated payment
              gateway shall be credited to the Player's "Unutilized Account".
              <br />
              Each time a Player participates in any contest on the PICKMONK
              Platform, the pre-designated amount shall be debited from the
              Player's account. In debiting amounts from the Player's accounts
              towards the pre-designated amount of such player shall be debited
              from the Player's Unutilized Account and thereafter, any remaining
              amount of participation fee shall be debited from the Player's
              Winning Account.
              <br />
              In case there is any amount remaining to be paid by the Player in
              relation to such Player's participation in any match(s) or
              Contest(s), the Player will be taken to the designated payment
              gateway to give effect to such payment. In case any amount added
              by the Player through such payment gateway exceeds the remaining
              amount of the pre-designated amount, the amount in excess shall be
              transferred to the Player's "Unutilized Account" and will be
              available for use in participation in any match(s) or Contest(s).
              <br />
              Debits from the "Unutilized Account" for the purpose of enabling a
              player's participation in a Contest shall be made in order of the
              date of credit of amounts in the "Unutilized Account", and
              accordingly amounts credited into "Unutilized Account" earlier in
              time shall be debited first.
              <br />
              All amounts credited into a Player's "Unutilized Account" must be
              utilized within 335 days of credit.
              <br />
              In case of any unutilized amount lying in the "Unutilized Account"
              after the completion of 335 days from the date of credit of such
              amount, PICKMONK reserves the right to forfeit such unutilized
              amount, without liability or obligation to pay any compensation to
              the Player.
              <br />
              Withdrawal of any amount standing to the Player's credit in the
              Winnings Account may be made by way of a request to PICKMONK.
              <br />
              PICKMONK shall effect an online transfer to the Player's bank
              account on record with PICKMONK within a commercially reasonable
              period of time. Such transfer will reflect as a debit to the
              Player's Winnings Account. PICKMONK shall not charge any
              processing fee for the online transfer of such amount from the
              Winnings Account to the Player's bank account on record with
              PICKMONK.
              <br />
              Players are requested to note that they will be required to
              provide valid photo identification and address proof documents for
              KYC Purposes, Proof of Identity, and Address in order for PICKMONK
              to process the withdrawal request.
              <br />
              Players are requested to note that the name mentioned on the
              Player's photo identification document should correspond with the
              name provided by the Player at the time of registration on the
              PICKMONK Platform, as well as the name and address existing in the
              records of the Player's bank account as provided to PICKMONK.
              <br />
              In the event that no bank account has been registered by the
              Player against such Player's account with PICKMONK, or the Player
              has not verified his/her Player Account with PICKMONK, to
              PICKMONK's satisfaction and in accordance with these Terms and
              Conditions, and in case the Player fails to register a bank
              account with his/her Player Account and/or to verify his/her
              Player Account, PICKMONK shall not transfer any Winning amounts to
              the said Player
              <br />
              Further, in order to conduct promotional activities, PICKMONK may
              gratuitously issue  Bonus to the Player for the purpose of
              participation in any Contest(s) and no Player shall be permitted
              to transfer or request the transfer of any amount into the 
              Bonus. The usage of any  Bonus issued shall be subject to the
              limitations and restrictions, including without limitation,
              restrictions as to the time within which such Bonus must be
              used, as applied by PICKMONK and notified to the Player at the
              time of issue of such amount. The issue of any Bonus to the
              player is subject to the sole discretion of PICKMONK and cannot be
              demanded by any Player as a matter of right. The issue of any
              Bonus by PICKMONK on any day shall not entitle the player to
              demand the issuance of such Bonus at any subsequent period in
              time nor create an expectation of recurring issue of such
              Bonus by PICKMONK to such Player. The Bonus granted to the
              player may be used by such Player for the purpose of setting off
              against the contribution to the prize pool in any Contest, in
              accordance with these Terms and Conditions. The Bonus shall
              not be withdraw-able or transferrable to any other account of the
              Player, including the bank account of such Player, or of any other
              Player or person, other that as part of the winnings of a Player
              in any Contest(s).
              <br />
              In case the Player terminates his/her account with PICKMONK or
              such account if terminated by PICKMONK, all Bonus granted to
              the player shall return to PICKMONK and the Player shall not have
              any right or interest on such points.
              <br />
              All Bonus credited to the Player account shall be valid for a
              period of 14 days from the date of credit. The unutilized Bonus shall expire at the end of 14 days from the date of credit.
              <br />
              Players agree that once they confirm a transaction on PICKMONK,
              they shall be bound by and make payment for that transaction.
              <br />
              The Player acknowledges that subject to the time taken for bank
              reconciliations and such other external dependencies that PICKMONK
              has on third parties, any transactions on the PICKMONK Platform
              may take up to 24 hours to be processed.
              <br />
              The Player acknowledges and agrees that any amount paid or
              transferred into the Player's "Unutilized Account" or Winnings
              Account may take up to 24 hours to reflect in the Player's
              "Unutilized Account" or Winnings Account balance. Similarly, the
              utilization of the  Bonus or amount debited from the
              "Unutilized Account" or Winnings Account may take up to 24 hours
              to reflect in the Player's "Unutilized Account" or Winnings
              Account balance.
              <br />
              The player acknowledges and agrees not to raise any complaint or
              claim against PICKMONK in respect of any delay, including any lost
              opportunity to join any Contest or match due to delay in crediting
              of transaction amount into any of the Player's accounts.
              <br />
              The player acknowledges and agrees that a transaction, once
              confirmed, is final and that no cancellation is permissible.
              <br />
              PICKMONK may, in certain exceptional circumstances and at its sole
              and absolute discretion, refund the amount to the Player after
              deducting applicable cancellation charges and taxes. At the time
              of the transaction, Players may also be required to take note of
              certain additional terms and conditions and such additional terms
              and conditions shall also govern the transaction. To the extent
              that the additional terms and conditions contain any clause that
              is conflicting with the present terms and conditions, the
              additional terms and conditions shall prevail.
              <br /> <br />
              <h3 className="content_box_green_heading">
                Tabulation of Fantasy Points
              </h3>
              PICKMONK may obtain the score feed and other information required
              for the computation and tabulation of fantasy points from
              third-party service provider(s) and/or the official website of the
              match organizer. In the rare event that any error in the
              computation or tabulation of fantasy points, selection of winners,
              abandonment of a match, etc., as a result of inaccuracies in or
              incompleteness of the feed provided by the third-party service
              provider and/or the official website of the match organizer comes
              to its attention, PICKMONK shall use best efforts to rectify such
              error prior to the distribution of prizes. However, PICKMONK
              hereby clarifies that it relies on the accuracy and completeness
              of such third-party score/statistic feeds and does not itself
              warrant or make any representations concerning the accuracy
              thereof and, in any event, shall take no responsibility for
              inaccuracies in computation and tabulation of fantasy points or
              the selection of winners as a result of any inaccurate or
              incomplete scores/statistics received from such third party
              service provider.
              <br /> <br />
              Players and Participants agree not to make any claim or raise any
              complaint against PICKMONK in this respect.
              <br /> <br />
              <h3 className="content_box_green_heading">
                Selection and Verification of Winners{" "}
              </h3>
              Winners will be selected on the basis of the scores of the teams
              in the designated contest match, which may last anywhere between
              one day and/or an entire tournament, on the Contest(s). The
              Player(s) owning the Team(s) with the highest aggregate score in a
              particular match shall be declared the winner(s).
              <br /> <br />
              In pre–specified Contests, PICKMONK may declare more than one
              winner and distribute prizes to such winners in increasing order
              of their Team's aggregate score at the end of the designated match
              of the Contest. The contemplated number of Winners and the prize
              due to each winner, in such contest shall be as specified on the
              Contest page, prior to the commencement of the Contest.
              <br /> <br />
              The Players creating Teams on behalf of any other Player or person
              shall be disqualified.
              <br /> <br />
              In the event of a tie, the winning Player shall be declared the
              Winner and the prize shall be equally divided amongst such
              Players.
              <br /> <br />
              PICKMONK is not liable to pay any prize if it is discovered that
              the Winner(s) have not abided by the Terms and Conditions, and
              other rules and regulations in relation to the use of PICKMONK
              Platform, Services, Contests, Fantasy Rules etc.
              <br /> <br />
              Winners shall be contacted by PICKMONK or the third party
              conducting the Contest at the Email Address provided at the time
              of registration. The verification process and the documents
              required for the collection of the prize shall be detailed to the
              Winners at this stage. The winners will be required to provide the
              following documents:
              <br />
              Photocopy of the Player's PAN Card;
              <br />
              Photocopy of the Government issued residence proof;
              <br />
              Player's bank account details and proof of the same.
              <br />
              <br />
              The list of Winners shall be posted on a separate web – page on
              PICKMONK and they also will be intimated by the E-mail Address
              provided at the time of registration. In the event that a Player
              has been declared a winner on the above-mentioned web – page, but
              has not received any communication from PICKMONK, such Player may
              contact PICKMONK within the time specified on the webpage.
              <br /> <br />
              PICKMONK shall not permit the Winner(s) to withdraw the
              prize/accumulated winnings unless the above–mentioned documents
              have been received and verified within the time – period
              stipulated on the Platform.
              <br /> <br />
              The Player represents and warrants that the documents provided in
              the course of the verification process are true copies of the
              original documents to which they relate.
              <br /> <br />
              The Players are required to provide proper and complete details at
              the time of registration. PICKMONK shall not be responsible for
              communication errors, commissions, and/or omissions including
              those of the Players due to which the results may not be
              communicated to the Winner.
              <br /> <br />
              <h3 className="content_box_green_heading">
                Conditions relating to the Prizes
              </h3>
              Only those Winners who have successfully completed the
              verification process mentioned herein and provided the Platform
              with the requisite documents within the time limit specified on
              the Platform shall be permitted to withdraw/receive their
              accumulated winnings, or any part thereof.
              <br /> <br />
              PICKMONK shall not entertain any claims or requests for the
              extension of time for submission of the Documents.
              <br /> <br />
              PICKMONK shall scrutinize all the documents submitted and may, at
              its sole and absolute discretion, disqualify any Winner from
              withdrawing his accumulated winnings, or any part thereof, on the
              following grounds:
              <br />
              Determination by PICKMONK that any document or information
              submitted by the Player is incorrect, misleading, false, fake,
              fabricated, incomplete, illegible, etc, or
              <br />
              The player does not fulfill the Eligibility criteria specified in
              the terms and conditions; or <br />
              Any other reason not stipulated herein
              <br /> <br />
              All prizes and winnings shall be subject to deduction of tax
              (hereinafter referred to as "Tax Deducted at Source (TDS)").
              <br /> <br />
              The TDS rates prescribed by the Government of India (GoI) with
              respect to any prize amount that is in excess of Rs.
              10,000/- is 30%. TDS of 30% shall be deducted from the Net
              Winnings. In case of any revisions by the GoI to the
              aforementioned rates in the future, TDS will be deducted by
              PICKMONK in accordance with the then-current prescribed TDS rate.
              <br /> <br />
              The Winners will be provided with TDS Certificates with respect to
              such tax deductions to their E-mail Address. The Winners shall be
              responsible for payment of any other applicable taxes, including
              but not limited to, income tax, gift tax, etc in respect of the
              prize .
              <br /> <br />
              <h3 className="content_box_green_heading">
                Legality of the Daily Fantasy Sports Game{" "}
              </h3>
              The Daily Fantasy Sports (DFS) has been considered a game of skill
              and the same has been considered legal, as they are excluded from
              the ambit of Indian gambling Legislations including, the Public
              Gambling Act, 1867. In 2017, the High Court of Punjab and Haryana
              in the case of Shri Varun Gumber vs. Union Territory of Chandigarh
              and others (CWP No. 7559 of 2017) became the first Indian Court to
              rule that the Daily Fantasy Sports Game is a game predominantly
              based on skill. The Punjab & Haryana High Court also observed that
              playing fantasy sports games required the same level of skills,
              judgment, and discretion as in the case of horse racing. An SLP
              came to be filed in the year 2019 against the decision passed by
              the Punjab & Haryana High Court and the two–judge Bench of Hon'ble
              Supreme court passed an order dismissing the said SLP. Thus, the
              order of the Hon'ble P&H High Court has reached its finality in
              relation to the DFS Games, and the same is considered a game of
              skill. The Hon'ble Supreme Court in the cases of State of Andhra
              Pradesh vs. K. Satyanarayana (AIR 1968 SC 825) and KR Lakshmanan
              vs. State of Tamil Nadu (AIR 1996 SC 1153) has held that a game in
              which success depends predominantly upon the superior knowledge,
              training, attention, experience, and adroitness of the player
              shall be classified as a game of skill.
              <br /> <br />
              It is clarified that PICKMONK has a gaming policy of "Excluded
              Territories" which means that for players from certain states
              where DFS Games are prohibited to play, PICKMONK India Private
              Limited will not register the said Players on its Platform.
              However, PICKMONK takes no responsibility for the Players who
              belong to such Excluded Territories, use varied means and tricks
              unknown to PICKMONK, to register themselves on the Platform
              <br /> <br />
              The Contest(s) described above are games of skill as the success
              of the Players primarily depends on their knowledge of cricket,
              knowledge of the player's relative form, performance in a
              particular territory, wisdom, skills, conditions and/or formats,
              attention and dedication towards the Contest(s) in playing the
              Contest(s). The Contest also requires the Players to field
              well–balanced sides with limited resources and make substitutions
              at appropriate times to gain maximum points.
              <br /> <br />
              By participating and playing in this Contest(s), each Player
              acknowledges and agrees that he/she is participating in a game of
              skill.
              <br /> <br />
              <h3 className="content_box_green_heading">
                Dispute and Dispute Resolution
              </h3>
              The Courts of Competent Jurisdiction at Ahmedabad shall have the
              exclusive jurisdiction to determine any and/or all disputes
              arising out of, or in connection with, the PICKMONK Services
              provided on the PICKMONK Platform, including the Contest(s), the
              construction, validity, interpretation, and enforceability of the
              Terms and Conditions, or the rights and obligations of the User(s)
              (including the Player(s)) or PICKMONK, as well as the exclusive
              jurisdiction to grant interim or preliminary relief in case of any
              dispute referred to arbitration as given below. All such issues
              and questions shall be governed and construed in accordance with
              the laws of the Republic of India.
              <br /> <br />
              In the event of any legal dispute, which may be a legal issue or
              question of fact, which may arise, the party raising the dispute
              shall provide written notification, to the other party. On receipt
              of the written notification, the parties shall first try to
              resolve the dispute through discussions. In the event that the
              parties are unable to resolve the dispute within fifteen (15) days
              of receipt of written notification, the dispute shall be settled
              by arbitration.
              <br /> <br />
              The place of arbitration shall be Ahmedabad, India. All
              arbitration proceedings shall be conducted in English and in
              accordance with the provisions of the Arbitration and Conciliation
              Act, 1996, as amended from time to time.
              <br /> <br />
              The arbitration award will be final and binding on the Parties,
              and each Party will bear its own costs of arbitration and equally
              share the fees of the arbitrator unless the arbitral tribunal
              decides otherwise. The arbitrator shall be entitled to pass
              interim orders and awards, including the orders for specific
              performance and such orders would be enforceable in competent
              courts. The arbitrator shall give a reasoned award.
              <br /> <br />
              Nothing contained in these Terms and Conditions shall prevent
              PICKMONK from seeking and obtaining interim or permanent equitable
              or injunctive relief, or any other relief available to safeguard
              PICKMONK's interest prior to, during, or following the filing of
              arbitration proceedings or pending the execution of a decision or
              award in connection with any arbitration proceedings from any
              court having jurisdiction to grant the same. The pursuit of
              equitable or injunctive relief shall not constitute a waiver on
              the part of PICKMONK to pursue any remedy for monetary damages
              through the arbitration described herein.
              <br /> <br />
              <h3 className="content_box_green_heading">Publicity</h3>
              Acceptance of a prize by the Winner constitutes permission for
              PICKMONK, and its affiliates to use the Winner's name, likeness,
              voice, and comments for advertising and promotional purposes in
              any media worldwide for purposes of advertising and trade without
              any further permissions or consents and/or additional compensation
              whatsoever.
              <br /> <br />
              The Winners further undertake that they will be available for
              promotional purposes as planned and desired by PICKMONK without
              any charge. The exact dates for the promotional activities remain
              at the sole discretion of PICKMONK.
              <br /> <br />
              Promotional activities may include but not be limited to press
              events, internal meetings, and ceremonies/functions.
              <br /> <br />
              <h3 className="content_box_green_heading">General Conditions</h3>
              If it comes to the notice of PICKMONK that any governmental,
              statutory, or regulatory compliances or approvals are required for
              conducting any Contest(s) or if it comes to the notice of PICKMONK
              that conduct of any such Contest(s) is prohibited, then PICKMONK
              Platform shall withdraw and/or cancel such Contest(s) without
              prior notice to any Participants or winners of any Contest(s).
              <br /> <br />
              Players agree not to make any claim in respect of such
              cancellation or withdrawal of the Contest or Contests in any
              manner.
              <br /> <br />
              Employees, directors, affiliates, relatives, and family members of
              PICKMONK, will not be eligible to participate in any Contest(s).
              <br /> <br />
              <h3 className="content_box_green_heading">
                Release and Limitations of Liability
              </h3>
              Players shall access the Services provided on PICKMONK voluntarily
              and at their own risk.
              <br /> <br />
              Players agree that PICKMONK shall, under no circumstances be held
              responsible or liable on account of any loss or damage sustained
              (including but not limited to any accident, injury, death, or loss
              of property) by Players or any other person or entity during the
              course of access to the PICKMONK Services (including participation
              in the Contest(s)) or as a result of acceptance of any prize.
              <br /> <br />
              By entering the contests and accessing the PICKMONK Platform and
              its Services provided therein, Players hereby release from and
              agree to indemnify PICKMONK, and/ or any of its directors,
              employees, partners, associates, and licensors, from and against
              all liability, cost, loss or expense arising out their access to
              the PICKMONK Services including (but not limited to) personal
              injury and damage to property and whether direct, indirect,
              consequential, foreseeable, due to some negligent act or omission
              on their part, or otherwise.
              <br /> <br />
              PICKMONK accepts no liability, whether jointly or severally, for
              any errors or omissions, whether on behalf of itself or third
              parties in relation to the prizes.
              <br /> <br />
              Players shall be solely responsible for any consequences which may
              arise due to their access to PICKMONK Platform and its Services by
              conducting an illegal act or due to non-conformity with these
              Terms and Conditions and other rules and regulations in relation
              to PICKMONK Services, including the provision of incorrect address
              or other personal details.
              <br /> <br />
              Players also undertake to indemnify PICKMONK and their respective
              officers, directors, employees, and agents on the happening of
              such an event (including without limitation cost of an attorney,
              legal charges etc.) on a full indemnity basis for any loss/damage
              suffered by PICKMONK on account of such activity on the part of
              the Players.
              <br /> <br />
              Players shall indemnify, defend, and hold PICKMONK harmless from
              any third party/entity/organization claims arising from or related
              to such Player's engagement with the PICKMONK or participation in
              any Contest.
              <br /> <br />
              In no event shall PICKMONK be liable to any Participant, User,
              and/or Player for acts or omissions arising out of or related to
              Player's engagement with PICKMONK or his/her participation in any
              Contest(s).
              <br /> <br />
              In consideration of PICKMONK allowing Participants, Players, and
              Users to access the PICKMONK Platform and its Services, to the
              maximum extent permitted by law, the Participants, Players, and
              Users waive and release each and every right or claim, all
              actions, causes of actions (present or future) each of them has or
              may have against PICKMONK, its respective agents, directors,
              officers, business associates, group companies, sponsors,
              employees, or representatives for all and any injuries, accidents,
              or mishaps (whether known or unknown) or (whether anticipated or
              unanticipated) arising out of the provision of PICKMONK Services
              or related to the Contests or the prizes of the Contests.
              <br /> <br />
              <h3 className="content_box_green_heading">Disclaimers </h3>
              To the extent permitted under law, neither PICKMONK nor its
              parent-holding company, subsidiaries, affiliates, directors,
              officers, professional advisors, or employees shall be responsible
              for the deletion, the failure to store, the misdelivery, or the
              untimely delivery of any information or material.
              <br /> <br />
              To the extent permitted under law, PICKMONK shall not be
              responsible for any harm resulting from downloading or accessing
              any information or material, the quality of servers, games,
              products, PICKMONK services or sites, cancellation of competition,
              and prizes.
              <br /> <br />
              PICKMONK disclaims any responsibility for, and if a Player pays
              for access to one of PICKMONK's Services, the Player will not be
              entitled to a refund as a result of, any inaccessibility that is
              caused by PICKMONK's maintenance on the servers or the technology
              that underlies our sites, failures of PICKMONK's service providers
              (including telecommunications, hosting, and power providers),
              computer viruses, natural disasters or other destruction or damage
              of our facilities, acts of nature, war, civil disturbance, or any
              other cause beyond our reasonable control. In addition, PICKMONK
              does not provide any warranty as to the content on the PICKMONK.
              <br /> <br />
              Players agree that PICKMONK's content is distributed on an "as is,
              as available" basis.
              <br /> <br />
              Any material accessed, downloaded, or otherwise obtained through
              PICKMONK is done at the Player's discretion, competence,
              acceptance, and risk, and the Player will be solely responsible
              for any potential damage to the Player's computer system or loss
              of data that results from a Player's download of any such
              material.
              <br /> <br />
              PICKMONK shall make best endeavors to ensure that the PICKMONK
              Platform is error-free and secure, however, neither PICKMONK nor
              any of its partners, licensors, or associates makes any warranty
              that:
              <br />
              PICKMONK will meet Players' requirements,
              <br />
              PICKMONK will be uninterrupted, timely, secure, or error-free
              <br />
              the results that may be obtained from the use of PICKMONK will be
              accurate or reliable; and <br />
              the quality of any products, PICKMONK Services, information, or
              other material that Players purchase or obtain through its website
              and/or application will meet their Players' expectations.
              <br />
              <br />
              In case PICKMONK discovers any error, including any error in the
              determination of Winners or in the transfer of amounts to a
              Player's account, PICKMONK reserves the right (exercisable at its
              discretion) to rectify the error in such manner as it deems fit,
              including through a set-off of the erroneous payment from amounts
              due to the Player or deduction from the Player's account of the
              amount of erroneous payment. In case of the exercise of remedies
              in accordance with this clause, PICKMONK agrees to notify the
              Player of the error and of the exercise of the remedy to rectify
              the same.
              <br /> <br />
              To the extent permitted under law, neither PICKMONK nor its
              partners, licensors, or associates shall be liable for any direct,
              indirect, incidental, special, or consequential damages arising
              out of the use of or inability to use our sites, even if we have
              been advised of the possibility of such damages.
              <br /> <br />
              Any PICKMONK Services, events, or Contest(s) being hosted or
              provided, or intended to be hosted on the PICKMONK platform and
              requiring specific permission or authority from any statutory
              authority or any state or the central government, or the board of
              directors shall be deemed cancelled or terminated if such
              permission or authority is either not obtained or denied either
              before or after the availability of the relevant PICKMONK
              Services, events or Contest(s) are hosted or provided.
              <br /> <br />
              To the extent permitted under law, in the event of suspension or
              closure of any Services, events, or Contests, Players (including
              Participants) shall not be entitled to make any demands, or
              claims, of any nature whatsoever.
              <br /> <br />
              <h3 className="content_box_green_heading">
                Grievance Redressal Mechanism
              </h3>{" "}
              <br />
              In case you have any complaints or grievances pertaining to: Any
              Player Content that you believe violates these Terms (other than
              an infringement of Intellectual Property Rights) for the reporting
              mechanism for such claims),
              <br />
              Player's access to the Platform or
              <br />
              Any User Content which the Player believes is, prima facie, in the
              nature of any material which is obscene, defamatory towards the
              complainant or any person on whose behalf the Player is making the
              complaint, or is in the nature of impersonation in an electronic
              form, including artificially morphed images of such individual,
              please share the same with us by notifying to us, in writing.
              <br /> <br />
              In your complaint or grievance, please include the following
              information:
              <br />
              Player's name and contact details: name, address, contact number,
              and email address;
              <br />
              Player's relation to the subject matter of the complaint, i.e.
              complainant or person acting on behalf of an affected person;
              <br />
              The name and age of the person aggrieved or affected by the
              subject matter of the complaint, in case the Player is acting on
              behalf of such person, and a statement, under penalty of perjury,
              that the Player is authorized to act on behalf of such person and
              to provide such person's personal information to us in relation to
              the complaint/grievance;
              <br />
              Description of the complaint or grievance with clear
              identification of the User Content in relation to which such
              complaint or grievance is made;
              <br />
              A statement that you, as an Individual, believe, in good faith,
              that the Player Content violates these Terms;
              <br />
              A statement that the information provided in the complaint or
              grievance is accurate.
              <br /> <br />
              PICKMONK respects the Intellectual Property Rights of others. All
              names, logos, marks, labels, trademarks, copyrights, or
              intellectual and proprietary rights on the Platform belonging to
              any person (including the Player), entity, or third party are
              recognized as proprietary to the respective owners. The Player is
              requested to send us a written notice/ intimation if you notice
              any act of infringement on the Platform, which must include the
              following information:
              <br />
              A clear identification of the copyrighted work allegedly
              infringed; A clear identification of the allegedly infringing
              material on the Platform;
              <br />
              The contact details: name, address, e-mail address, and phone
              number; A statement that you as an Individual believe, in good
              faith, that the use of the copyrighted material allegedly
              infringed on the Platform is not authorized by your agent or the
              law;
              <br />
              A statement that the information provided in the notice is
              accurate, and under penalty of perjury, that the signatory is
              authorized to act on behalf of the owner of an exclusive copyright
              right that is allegedly infringed;
              <br />
              The Player's signature or the signature of his/her authorized
              agent.
              <br /> <br />
              The aforesaid notices can be sent to the Company by email at
              _____________ .
              <br /> <br />
              On receiving such complaint, grievance, or notice, the Company
              reserves the right to investigate and/or take such action as the
              Company may deem appropriate.
              <br /> <br />
              The Company may reach out to the Player to seek further
              clarification or assistance with the investigation, or verify the
              statements made in the complaint, grievance, or notice, and the
              Player acknowledges providing timely assistance with the
              investigation which would facilitate the redressal of the same.
              <br /> <br />
              The name and title of the Grievance Redressal Officer is as
              follows:
              <br />
              Name: Ms/Mr. ________
              <br />
              Email: ___________
              <br />
              Address: _________
              <br />
              <br />
              The Grievance Officer identified above pursuant to the provisions
              of applicable laws including but not limited to the Information
              Technology Act, 2000 and the Consumer Protection Act, 2019, and
              the Rules enacted under those laws.
              <br /> <br />
              The Company reserves the right to replace the Grievance Redressal
              Officer at its discretion through the publication of the name and
              title of such replacement on the website, which replacement shall
              come into effect immediately upon publication.
              <br /> <br />
              Standard Terms and Conditions of "Referral Players" and/or "Invite
              Friends"
              <br /> <br />
              The PICKMONK Referral Players/Invite Friends Program lets you
              invite friends to join the PICKMONK Platform. In the event that
              the Player and their referred friend meet the criteria and
              complete all the steps specified in these terms, the Player can
              earn a Bonus from PICKMONK of upto Rs. ____ (" Bonus
              Amount"), which Bonus Amount will be redeemable to join paid
              contests and contests through the PICKMONK's mobile application
              for the iOS and/or Android mobile devices and the Referred Players
              will receive discount coupons worth Rs. ____ ("Invitee Discount").
              To participate in the Program, please note the terms and
              conditions in this respect, as they govern the Player's
              participation in the Program:
              <br /> <br />
              Eligibility – All Players who:
              <br />
              have an account registered with PICKMONK's daily fantasy gaming
              platform;
              <br />
              are eligible to participate in the pay-to-play game, as per the
              Terms and Conditions mentioned herein; and
              <br />
              have downloaded and installed the PICKMONK Application on their
              respective mobile devices, will be eligible to participate in the
              Program.
              <br /> <br />
              Player's consent is that Participation in the Program by proxy is
              not permitted.
              <br /> <br />
              Player's agree that Participation in the Program is voluntary. A
              Player shall not register or operate more than one user account
              with PICKMONK and shall not participate in the Program with more
              than one user account with PICKMONK.
              <br /> <br />
              By participating in the Program, the Player agrees to and accepts
              these Terms.
              <br /> <br />
              For the purpose of participation in the Program, the Players are
              required to have the PICKMONK Application downloaded and installed
              on their mobile devices. Through the PICKMONK Application, the
              Player will be provided with a unique link or code, which can be
              shared by the Player ("Inviter") with their Referral Friends (each
              an "Invitee") for the purpose of inviting such friends to create
              and register an account with PICKMONK and download the PICKMONK
              Application. On receiving the link or code from the Inviter, the
              Invitee may either: Click on the link, consequent to which such
              Invitee will be directed to a registration page and will be
              provided the option to register an account with PICKMONK and
              download and install the PICKMONK Application on his/her device;
              or Download and install the PICKMONK Application on his/her device
              independently, register for a PICKMONK account through the
              PICKMONK Application, and enter the unique code shared by the
              Inviter where prompted in the PICKMONK Application.
              <br /> <br />
              The Inviter and the Invitee will be eligible to earn the Bonus
              Amount subject to and amongst other terms specified in these
              Terms:
              <br />
              The Invitee not being an existing user of PICKMONK; and
              <br />
              The Inviter and Invitee are eligible to participate in the pay-to
              play PICKMONK fantasy cricket, and in the future various other
              fantasy games introduced by PICKMONK; and
              <br />
              The Invitee successfully registers for an account with PICKMONK
              through the unique link or by using the unique code shared by the
              Inviter; and
              <br />
              The Inviter and Invitee agree to the license agreement for the
              PICKMONK Application and downloading and installing the PICKMONK
              Application as available for the Inviter's and Invitee's
              respective mobile devices. For the purposes of these Terms, an
              "existing user of PICKMONK" shall mean a user who presently
              operates an account with the Platform or operated an account with
              the Platform at any point in time.
              <br /> <br />
              An Invitee who is an existing user of PICKMONK is not permitted to
              register a new account with the Platform for the purpose of
              availing of the Bonus Amount. PICKMONK will determine in its sole
              discretion whether an Invitee is an existing user of PICKMONK or
              not and take appropriate action.
              <br /> <br />
              In the event that the Invitee opts to register for a PICKMONK
              account through the PICKMONK Application, the Invitee can verify
              his/her contact information at the time of registration.
              <br /> <br />
              The verification process may require an Inviter/Invitee to submit
              personal information about the user (Inviter/Invitee) and
              documents identifying the Inviter/Invitee.
              <br /> <br />
              The Inviter agrees to receive communication and notifications from
              PICKMONK and to allow PICKMONK to communicate with Invitees
              referred by you about the Inviter's participation in the Program.
              <br /> <br />
              Any information collected in respect of the Inviter/Invitee as
              part of the Program or otherwise in the course of such person's
              use of the Website shall be subject to PICKMONK's Privacy Policy
              stated herein.
              <br /> <br />
              The verification of an Inviter/Invitee shall be completed at the
              time of first withdrawal by the Inviter/Invitee from the
              Inviter's/Invitee's "Winnings Account" with the Platform.
              <br /> <br />
              An Inviter/Invitee may voluntarily seek verification of the
              Inviter/Invitee by clicking on the "Verify Now" tab on the
              Winnings tab on the My Balance Page of the Inviter/Invitee's
              account with the Platform.
              <br /> <br />
              The Invitee will be eligible to receive a maximum discount of Rs.
              ____ ("Invitee Discount"). This Invitee Discount shall be
              available for utilisation as per the table below. Please note the
              promotions mentioned herein are non-shareable and can be used only
              by the user who receives the specific communication from the
              Platform.
              <br /> <br />
              The Players agree that the Promotions are available to use within
              seven (7) days of registration on the platform.
              <br /> <br />
              Inviter Bonus:
              <br />
              For an Inviter to be eligible to earn the Bonus Amount due to
              him/her ("Inviter Bonus"), the Inviter must also download and
              install the PICKMONK Application on his/her mobile device.
              <br />
              The credit of the Inviter Bonus is contingent on the Invitee's
              deposit of any Amount in its Unutilised Account and using such
              amounts to participate in paid contests on the Platform.
              <br />
              The Inviter shall be eligible to receive 10% of the deposit amount
              used by the Invitee to join any paid contests on the Platform and
              the same shall be subject to a maximum of Rs. ____. As an example,
              in the event that the invitee uses Rs. 100 from his Unutilized
              Account to join a paid contest on the Platform, the Inviter shall
              be eligible to receive Rs. 10 as Inviter Bonus upon the successful
              completion of such contest.
              <br />
              Subject to the provisions of i) and ii) above, the applicable
              Inviter Bonus earned by the Inviter shall be credited to the
              Inviter's  Bonus Account within fifteen (15) days of the
              completion of the paid contest.
              <br />
              It is clarified that the Inviter will be eligible to receive the
              Inviter Bonus with respect to any contests only in the event the
              winners are declared for such contests. In the event that the
              contest entry amount paid by the Invitee is refunded to the
              Invitee with respect to any paid contest, for any reason, the
              Inviter shall not be eligible to receive any Inviter Bonus for
              such contest or contest.
              <br /> <br />
              The Bonus Amounts credited to the Inviter can be used by the
              Inviter/ to join paid contests and contests offered by PICKMONK
              through the Platform. No part of the Bonus Amount may be used to
              join private contests or be withdrawn or transferred to any other
              balance account held by the Inviter/Invitee with PICKMONK or
              to any third party account or to any bank/payment instrument
              account.
              <br /> <br />
              The Player consents that the bonus amount shall expire and be
              without effect at the end of Fourteen Days (14) from the date of
              credit of the bonus amount.
              <br /> <br />
              The deposit of the Bonus Amount shall be at the sole discretion of
              PICKMONK and shall be subject to the Inviter's/Invitee's
              compliance with the Terms mentioned herein. PICKMONK may
              substitute or change the Bonus Amount offered under the Program at
              any time without notice. An Inviter may not substitute the amount
              of Bonus Amount or substitute offering for other items.
              <br /> <br />
              PICKMONK reserves the right to:
              <br />
              withhold the deposit of the Bonus Amount; and/or
              <br />
              forfeit any deposited Bonus Amount to an Inviter/Invitee or any
              prizes/winnings earned by the participant by using such Bonus
              Amount; and/or
              <br />
              deactivate the accounts of the Inviter/Invitee, in the event that
              it determines or reasonably believes that such Inviter/Invitee has
              violated these Terms or the terms and conditions of the PICKMONK
              Platform and its Services.
              <br /> <br />
              The Player agrees that mere participation in the Program does not
              entitle the Inviter to receive any Bonus Amount.
              <br /> <br />
              PICKMONK may, at its sole and absolute discretion, disqualify any
              Inviter/Invitee if such Inviter/Invitee engages in or it is found
              that such Inviter/Invitee has engaged in any illegal, unlawful, or
              improper conduct (with regard to the Program or otherwise).
              <br /> <br />
              The decision of PICKMONK will be final and binding with regard to
              the Program, and the deposit of the Bonus Amount and the Invitee
              Discount, and no correspondence, objection, complaints, etc. will
              be entertained in this regard.
              <br /> <br />
              This Program cannot be clubbed with any other
              contests/promotions/programs that are running simultaneously and
              organized or conducted by PICKMONK.
              <br /> <br />
              PICKMONK reserves the right to change, modify or withdraw the
              Program and/or change any of these terms and conditions without
              any prior notice of the same at its sole discretion.
              <br /> <br />
              The Terms and Conditions, as applicable to PICKMONK's fantasy
              games and services, will apply to and govern the Referral Friends
              Program.
              <br /> <br />
              PICKMONK does not make any commitment, express or implied, to
              respond to any feedback, suggestion, and, or, queries of the
              participants (Inviter/Invitee) of the Referral Friends Program.
              <br /> <br />
              Notwithstanding anything contained herein, the aggregate liability
              of PICKMONK to a participating Inviter/Invitee in relation to the
              Referral Friends Program for any reason whatsoever shall not
              exceed Rs. ____.
              <br /> <br />
              <h3 className="content_box_green_heading">Miscellaneous</h3>
              PICKMONK may be required under certain legislations, to notify its
              Players of certain events.
              <br /> <br />
              The Players hereby acknowledge and consent that such notices will
              be effective upon PICKMONK posting them on the PICKMONK Platform
              or delivering them to the Player through the registered email
              address provided by the Player at the time of registration. The
              Player(s) may update their email address by logging into their
              account on the PICKMONK. If they do not provide PICKMONK Platform
              with accurate information, PICKMONK India Private Limited cannot
              be held liable for failure to notify the Player.
              <br /> <br />
              PICKMONK shall not be liable for any delay or failure to perform
              resulting from causes outside its reasonable control, including
              but not limited to any failure to perform due to unforeseen
              circumstances or cause beyond PICKMONK's control such as acts of
              God, war, terrorism, riots, embargoes, acts of civil or military
              authorities, fire, floods, accidents, network infrastructure
              failures, strikes, or shortages of transportation facilities,
              fuel, energy, labor or materials or any cancellation of any the
              match to which the Fantasy Sports Contest relates. In such
              circumstances, PICKMONK shall also be entitled to cancel any
              related Contest(s) and to process an appropriate refund for all
              Players.
              <br /> <br />
              PICKMONK's failure to exercise or enforce any right or provision
              of these Terms and Conditions shall not constitute a waiver of
              such right or provision.
              <br /> <br />
              Players agree that regardless of any statute or law to the
              contrary, any claim or cause of action arising out of or related
              to the use of the PICKMONK or these Terms must be filed within
              thirty (30) days of such claim or cause of action arising or be
              forever barred.
              <br /> <br />
              These Terms and Conditions, including all terms, conditions, and
              policies that are incorporated herein by reference, constitute the
              entire agreement between the Players and PICKMONK India Private
              Limited and govern the Player's use of the PICKMONK's Platform and
              Services, superseding any prior agreements that any Player may
              have with PICKMONK India Private Limited.
              <br /> <br />
              If any part of these Terms and Conditions is determined to be
              indefinite, invalid, or otherwise unenforceable, the rest of these
              Terms and Conditions shall continue in full force.
              <br /> <br />
              PICKMONK reserves the right to moderate, restrict, or ban the use
              of the PICKMONK Platform and its Services, specifically to any
              Player, or generally, in accordance with PICKMONK's
              policy/policies from time to time, at its sole and absolute
              discretion and without any notice.
              <br /> <br />
              PICKMONK may, at its sole and absolute discretion, permanently
              close or temporarily suspend any PICKMONK Services (including any
              Contest(s)).
              <br /> <br />
              PICKMONK may from time to time conduct/organize, promotions/offers
              on the platform. Any two or more promotions cannot be clubbed
              together with any other promotions that are running simultaneously
              on the PICKMONK platform. Also, the Player agrees that the
              promotions/offers cannot be clubbed with Bonus available with
              any user.
              <br /> <br />
              <h3 className="content_box_green_heading">Definitions</h3>
              Cookie is an alphanumeric identifier that the Company transfers to
              the Player's hard drive through their web browser when they visit
              the website. It enables the Company's system to recognize its
              Players while visiting PICKMONK's website and thereby improving
              the services provided to its Players. Cookies may also be used to
              compile aggregate information about the areas of the website, most
              frequented by the Player. This traffic information can be used to
              enhance the content of the website and make it user-friendly.
              <br /> <br />
              Daily Fantasy Sports (abbreviation for "DFS") is a game of skill
              where the Players draft a team for one day (or weekend) of games
              only using the  Cap Format and compete for prizes.
              <br /> <br />
              Excluded Territories would mean the States and Union Territories
              where Daily Fantasy Sports Games is not considered a Game of skill
              and would include Assam, Andhra Pradesh, Tamil Nadu, Odisha,
              Telangana, Nagaland, and Sikkim.
              <br /> <br />
              Net Winnings would include the Player's winnings after deducting
              prize amount (-) contest entry amount (-) bonus.
              <br /> <br />
              Sensitive Personal Information would mean such personal
              information which consists of information relating to the Player's
              physical, psychological, and mental health condition, mental
              records and history, biometric information, sexual orientation,
              and financial information, such as information regarding the
              payment instrument and modes used by the Player to make such
              payments, which may include the card holder's name, credit and/or
              debit card number (in encrypted form) with the expiration date,
              banking details, wallet details, etc.
              <br /> <br />
              Spamming the Players may include but shall not be limited to
              sending unsolicited Emails to its Users, sending bulk Emails to
              PICKMONK Players, and/or would also include sending unwarranted
              Email content either to selected Players or in bulk.
              <br /> <br />
              Pre – designated fees would mean and include the predetermined
              platform fee for accessing the PICKMONK Services and Platform and
              pre – determined Player's contribution towards the prize
              pool.
              <br /> <br />
              Promotional Activities may include but not be limited to press
              events, internal meetings, and ceremonies/functions.            
            </p>
          </div>
          <div class="trapezoid_left "></div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default TermsAndConditions;
