import "./App.css";
import LogoIcon from "../src/assets/Logo.png";
import IosBtn from "../src/assets/ios_btn.png";
import PlayStoreBtn from "../src/assets/playstore_btn.png";
import phone_logo from "../src/assets/phone_logo.png";
import mail_logo from "../src/assets/mail_logo.png";
import Star from "../src/assets/AppRating_icon.png";
import Diamond from "../src/assets/DailyContest_icon.png";
import User from "../src/assets/SelectPlayer_icon.png";
import MainImg from "../src/assets/MobileWithbatsman_img.png";
import HomeSection2 from "../src/HomeSection_2";
import Footer from "../src/Footer";
import "./utils/styles/content.css";
import { Link } from "react-router-dom";
import { APP_STORE_URL, PLAY_STORE_URL, TAGS } from "./utils/constant";
import { Helmet } from "react-helmet";

function Home() {
  return (    

    <div className="home_page_container">
       <Helmet>
        <title> {TAGS["home"].title} </title>
        <meta name="description" content={TAGS["home"].description} />
        <link rel="canonical" href={TAGS["home"].link} />
      </Helmet>
      <div className="background-image_header">
        <div className="header p-md-3 p-2 text-end w-100">
          <b>
          <a href = "mailto: support@pickmonk.com"><span className="text-light p-md-3 p-1">support@pickmonk.com</span>
            <img src={mail_logo} alt="email icon" /></a>
          </b>
          <b>
          <a href="tel:+91 79 35106161"><span className="text-light p-md-3 p-2 ps-md-5 ps-4">+91 79 35106161</span>
           <img src={phone_logo} alt="phone call icon" /></a>
          </b>
        </div>

        <div className="ps-md-5 p-3 animate_fade">
          <Link to="/" >
            <img className="logo_icon" src={LogoIcon} alt="pickmonk fantasy app logo" />
          </Link>

          <div style={{ marginTop: "50px", position: "relative", zIndex: "1" }}>
            <h1 class="home_text_heading animate_fade">
              World's Fastest Growing <br />
              Fantasy Platform
            </h1>           
            <br />
            <br />
            <div className="p-2 header_btn mt-4 ">
              <Link to={PLAY_STORE_URL} target="_blank" rel="noopener noreferrer" >
                <img
                  class="p-1 hand header_icon "
                  src={PlayStoreBtn}
                  alt="google playstore download button"
                />
              </Link>
              <Link to={APP_STORE_URL} target="_blank" rel="noopener noreferrer">
                <img class="p-1 hand header_icon" src={IosBtn} alt="app store download button" />
              </Link>
            </div>
          </div>

          <div style={{ marginTop: "10  0px", overflow: "hidden" }}>
            <div class="col-12 col-md-12 col-sm-12  h_strip ">
              <div class="header_strip">
                <img src={Star} className="header_strip_icon" alt="rating star" />
                <div className="d-flex align-items-center">
                  <div className="diamond_points">4.3</div>
                  <div className="diamond_text">App Rating</div>
                </div>
              </div>

              <div class="header_strip header_strip_2__ml">
                <img src={User} className="header_strip_icon" alt="select 11 players" />
                <div className="d-flex align-items-center">
                  <div className="diamond_points">11</div>
                  <div className="diamond_text">Please Select 11 Players</div>
                </div>
              </div>

              <div class="header_strip header_strip_3__ml">
                <img src={Diamond} className="header_strip_icon" alt="contest icon" />
                <div className="d-flex align-items-center">
                  <div className="diamond_points">2K+</div>
                  <div className="diamond_text">Daily Contests</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img className="header_img animate_fade" src={MainImg} alt="Pickmonk app screenshot 1" />
        </div>

        
      </div>

      <HomeSection2 />
      <Footer />
    </div>
  );
}

export default Home;
