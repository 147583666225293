import "./App.css";
import "./utils/styles/content.css";
import phone_logo from "../src/assets/phone_logo.png";
import mail_logo from "../src/assets/mail_logo.png";
import IosBtn from "../src/assets/ios_btn.png";
import PlayStoreBtn from "../src/assets/playstore_btn.png";
import LogoIcon from "../src/assets/Logo.png";
import Footer from "../src/Footer";
import { Link } from "react-router-dom";
import { APP_STORE_URL , TAGS } from "./utils/constant";
import { Helmet } from "react-helmet";

function Faq() {
  return (
    <div className="background-image mb-5">
      <Helmet>
        <title> {TAGS["faqs"].title} </title>
        <meta name="description" content={TAGS["faqs"].description} />
        <link rel="canonical" href={TAGS["faqs"].link} />
      </Helmet>
      <div className="header p-md-3 p-2 text-end w-100">
        <b>
          <a href="mailto: support@pickmonk.com">
            <span className="text-light p-md-3 p-1">support@pickmonk.com</span>
            <img src={mail_logo} alt="email icon" />
          </a>
        </b>
        <b>
          <a href="tel:+91 79 35106161">
            <span className="text-light p-md-3 p-2 ps-md-5 ps-4">
              +91 79 35106161
            </span>
            <img src={phone_logo} alt="phone call icon" />
          </a>
        </b>
      </div>
      <Link to="/">
        <img className="logo_icon ms-4 mt-3" src={LogoIcon} alt="pickmonk fantasy app logo" />
      </Link>

      <div className="content_container ">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h1 className="content_box_heading">FAQ'S</h1>
          </div>
          <div className="col-md-12 col-sm-12 p-3 d-flex align-items-center justify-content-end">
            <div className="row">
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={APP_STORE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="store_icon" src={IosBtn} alt="app store download button" />
                </Link>
              </div>
              <div className="col-sm-6 col-6 p-sm-2 p-2 ">
                <Link
                  to={"https://play.google.com/store/apps/details?id=com.pickmonk"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="store_icon" src={PlayStoreBtn} alt="google playstore download button" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div class="trapezoid_right "></div>
          <div className="p-sm-5 p-3   bg-dark">
            <p className="content_box_text">
              <span className="content_box_green_heading">
                1. What is Pickmonk?
              </span>{" "}
              <br />
              Pickmonk is one of the fastest-growing online fantasy sports
              gaming platforms in India. Pickmonk enables users to earn 
              through their skills and knowledge of online fantasy sports
              gaming.
              <br /> <br />
              <span className="content_box_green_heading">
                2. How do I signup on Pickmonk App?
              </span>{" "}
              <br />
              Users can sign up on Pickmonk App using their mobile number or
              email address. Users can also signup directly through their
              Facebook or Google+ account.
              <br /> <br />
              <span className="content_box_green_heading">
                3. Can I have multiple accounts on Pickmonk?
              </span>
              <br />
              No. A user is allowed to have only one account under their name
              with valid supporting documents on Pickmonk. Please refer to the
              Fair Play Policy section for further details.
              <br />
              <br />
              <span className="content_box_green_heading">
                4. Can I update or edit my information on Pickmonk?
              </span>
              <br />
              Users can easily update/edit their information by logging into the
              App using their mobile number or registered email address.
              <br />
              <br />
              <span className="content_box_green_heading">
                5. I don't know how to play cricket, can I still play on
                Pickmonk?
              </span>
              <br />
              The online fantasy cricket gaming services offered by Pickmonk are
              for everyone including those who don't know much about cricket.
              So, yes, you can play even if you don't know much about cricket.
              In addition, the "How To Play" section of the Pickmonk App and
              website can assist you in playing the game by briefing you about
              the playing tactics.
              <br />
              <br />
              <br />
              <h3>Playing on Pickmonk:</h3>
              <br />
              <span className="content_box_green_heading">
                1. How Do I Play on Pickmonk?
              </span>
              <br />
              <h6>Please follow these simple steps:</h6>
              <p className="ms-4">
                1. Register/Login to Pickmonk App. <br />
                2. Select a match you want to join and then click on the 'Create
                New Team' button for creating your team.
                <br />
                3. Create your team of 11 players (including a Super Selection &
                Multiplier).
                {/* within the allotted 15 credits. */}
                <br />
                4. Join free or  contests of your choice. Paid contests let
                you earn winnings and require a contest entry amount, for which
                we provide multiple payment methods such as Credit Cards, Debit
                Cards, Net banking, and different digital wallet services
                (Paytm, PhonePe, MobiKwik, etc.).
                <br />
              </p>
              <span className="content_box_green_heading">
                2. How do I select my Team?
              </span>{" "}
              <br />
              Creating a team on Pickmonk App is very simple. The user creates
              their team by selecting the match they would like to join and then
              clicking on the "Create New Team" option available under the "My
              Teams" section. The user can then create his/her team by selecting
              11 players (including Super Selection and Multiplier).
              {/* within the
              allotted 15 credits. */}
              <br /> <br />
              <span className="content_box_green_heading">
                3. What is the use of Super Selection and Multiplier?
              </span>{" "}
              <br />
              The player chosen as a Super Selection in a team can help the user
              earn extra points. The player chosen as Super Selection is awarded
              2 times the original points whereas the player chosen as the
              Multiplier is awarded 1.5 times the original points according to
              their on-field performance.
              <br />
              <br />
              <span className="content_box_green_heading">
                4. Can I edit my team once I joined the contest?
              </span>{" "}
              <br />
              Users can edit their selections before the match starts. Once the
              match has started, no changes are allowed.
              <br /> <br />
              <span className="content_box_green_heading">
                5. How many teams can a user create in a match?
              </span>
              <br />
              A User can create multiple teams and join the contests for the
              match as per the contest rules (for example some contests will
              allow 5 teams per user, or 10 or 20, etc.)
              <br /> <br />
              <h3>About contests, Contest types, and Winners:</h3>
              <br />
              <span className="content_box_green_heading">
                1. What type of contests can I join?
              </span>{" "}
              <br />
              The users using the services and Fun Features provided by Pickmonk
              can join various types of contests organised by Pickmonk such as
              2-member, 3-member, 5-member and 10-member contests (depending on
              the number of competitors in a contest). Further, the users can
              join paid contest(s) to earn winnings or they can join the
              practice contests for free to enhance their playing skills.
              <br /> <br />
              <span className="content_box_green_heading">
                2. What are Public contests?
              </span>
              <br />
              The public contests organised by Pickmonk are contests that are
              open to everyone. Any Pickmonk user can join the public contests
              and be eligible for winning.
              <br />
              <br />
              <span className="content_box_green_heading">
                3. What are Private contests?
              </span>{" "}
              <br />
              The private contests organised on Pickmonk are contests that the
              user creates. In private contests, a user can create their contest
              with a pre-defined number of participants, and a pre-defined
              winnings amount. The private contest can be shared by the user
              among their friends via a unique code. As the private contest are
              contests created by the user, it is not visible to all the users
              of Pickmonk and only accessible to the users who have the unique
              code for the contest.
              <br /> <br />
              <span className="content_box_green_heading">
                4 How do I join contests?
              </span>
              <br />
              Users can easily join any of the contests organised by Pickmonk by
              just clicking the 'Join' button below the specific contest.
              <br />
              <br />
              <span className="content_box_green_heading">
                5. What are paid contests?
              </span>
              <br />
              The paid contests organised by Pickmonk are contests where users
              participate by paying a pre-defined contest entry amount and can
              compete with other Pickmonk users to win. The user whose team
              scores the highest aggregate of points will be declared the winner
              of the contest.
              <br />
              <br />
              <span className="content_box_green_heading">
                6. How are the winnings distributed in case of multiple winners?
              </span>{" "}
              <br />
              The users can check the distribution of winnings in the case of
              "Multiple Winners" by clicking on the pre-defined number of
              winners below each of the contests. In the event, the winning
              amount is more than ₹10,000, there will be tax deductions
              according to the government's taxation policies.
              <br />
              <br />
              <span className="content_box_green_heading">
                7. What happens if a match is completed as per the D/L method?
              </span>
              <br />
              In the event any match is completed through the D/L method, the
              match is considered as completed on Pickmonk and the points are
              awarded accordingly. In such cases, the ranks and points are
              awarded based on the live scores and no refund is made in such
              cases. However, if a match is being completed through the D/L
              method and a team plays for 50 overs in the first innings, the
              other team must play at least 20 overs in ODIs and 5 Overs in T20s
              for the second innings.
              <br />
              <br />
              <span className="content_box_green_heading">
                8. What happens if the match is abandoned?{" "}
              </span>
              <br />
              In the event an ODI/T20 is called-off, the Pickmonk contest will
              be cancelled and the contest entry fee will be refunded to the
              users. This applies to where the real match is abandoned either
              without a single ball being bowled, or the match is abandoned
              after it has begun.
              <br />
              <br />
              <br />
              <span className="content_box_green_heading">
              9. What if the contest doesn't meet the required number of
              participants? {" "}
              </span>
              <br />
              Pickmonk offers public contests as confirmed as well
              as non-confirmed contests on the platform. The confirmed contests
              are not cancelled even if they do not meet the pre-defined
              participant requirements, whereas non-confirmed contests are
              cancelled due to the required number of participants. In the case
              of Private contests, contests will be functional even with 2
              participants & the winnings will be automatically recalculated
              based on the number of participants that joined.
              <br />
              <br />
              <br />
              <h3>Account Balance and prizes:</h3>
              <br />
              <span className="content_box_green_heading">
                1. How will I be notified of my winnings?
              </span>
              <br />
              Pickmonk updates the final ranks and points immediately at the end
              of every match. In the event, a user wins prize, the
              winning amount will be credited automatically to the Pickmonk
              account wallet of the user and through email notification.
              <br />
              <br />
              <span className="content_box_green_heading">
                2. When will the winnings be distributed?
              </span>
              <br />
              Pickmonk usually completes the process of winner declaration and
              distribution of winnings amount among the winners within a few
              hours after the end of the match.
              <br />
              <br />
              <span className="content_box_green_heading">
                3. Does the amount on my winning account has any expiry date?
              </span>
              <br />
              The amount in the winnings account of the user should be withdrawn
              to the user's bank account within 365 days of the credit. In the
              event the user fails to withdraw his/her winnings within 365 days
              of credit, then the winnings amount of the user will be forfeited
              by Pickmonk.
              <br />
              <br />
              <span className="content_box_green_heading">
                4. How do I check my Pickmonk account balance?
              </span>
              <br />
              Users can check their Pickmonk account balance by clicking on the
              My Balance section available in the menu bar, for a detailed
              account balance and the most recent transactions on Pickmonk.
              <br />
              <br />
              <span className="content_box_green_heading">
                5. How can I add funds to my Pickmonk user account?
              </span>
              <br />
              Users can add funds to their Pickmonk account by using various
              transaction methods such as Debit Cards, Credit cards, Net
              banking, and various digital wallet services (Paytm, PhonePe,
              MobiKwik, etc.). Please reach out to us via our Contact Us page
              with any questions.
              <br />
              <br />
              <span className="content_box_green_heading">
                6. What if the payment is deducted from my card and not updated
                in the Pickmonk account?
              </span>
              <br />
              This usually happens when there is a delay in communication among
              the parties involved in the transaction, primarily from the bank's
              end. In such an event the users can reach out to us via our
              Contact Us page with a screenshot of their card/bank statement
              affirming that there has been a deduction from the user's card so
              that we can check and assist the users with their concerns and
              queries.
              <br />
              <br />
              <span className="content_box_green_heading">
                7. Does the amount in the Amount Added 'Unutilised' Account have
                any expiry date?
              </span>
              <br />
              Yes, the amount deposited in your unutilized accounts comes with
              an expiry date and should be used within 365 days of credit. In
              the event, the user does not use the amount deposited in their
              unutilised section within 365 days from the date of credit,
              Pickmonk is authorised to forfeit such unutilized amount, without
              any liability or obligation to pay any compensation to you.
              <br />
              <br />
              <span className="content_box_green_heading">
                8. What's the order in which my Account Balance gets utilised?
              </span>
              <br />
              Pickmonk first deducts the amount from your Unutilised account
              followed by your Bonus account, and lastly from your Winnings
              account to cover the contest entry amount for any contests that
              you join.
              <br />
              <br />
              <span className="content_box_green_heading">
                9. What if my transaction fails and the amount is deducted from
                my bank account?
              </span>
              <br />
              In case you are carrying out a transaction on Pickmonk and your
              transaction fails due to certain circumstances and the amount is
              deducted from your bank account, but is not added to your Pickmonk
              account, please contact the payment gateway you used for this
              transaction. The transaction failure amounts are to be refunded by
              the payment gateways as we do not receive any payments in case of
              transaction failures.
              <br />
              <br />
              <br />
              <h3>Verification Process and Withdrawals:</h3>
              <br />
              <span className="content_box_green_heading">
                1. Is verification necessary to join contests on Pickmonk?
              </span>
              <br />
              To join contests on Pickmonk, users are required to verify at
              least their email address and mobile number. However, users will
              not be eligible for Bonus. In addition, users shall not be
              allowed to withdraw until their account is verified by Pickmonk.
              <br />
              <br />
              <span className="content_box_green_heading">
                2. How do I get my Pickmonk account verified?
              </span>
              <br />
              The account verification process is very simple and a one-time
              process. To verify your account manually, go to the My Balance tab
              from the menu bar and verify it from there. Verify your account by
              following these steps:
              <br />
              <p className="ms-4">
                a. Enter your mobile number and then enter the OTP received on
                the mobile number to verify your mobile number.
                <br />
                b. Enter your email address and verify your email address by
                clicking the verification link sent by Pickmonk.
                <br />
                c. The third step of the verification process at Pickmonk is PAN
                card verification. To successfully verify your PAN card details,
                upload your PAN Card number, PAN Card image, and other required
                details. After successfully uploading and entering the required
                details click on 'Save PAN Card".
                <br />
                d. After successfully submitting your PAN card details, submit
                your bank account details for completing the verification
                process. PAN card and bank account details are verified on the
                same day.
                <br />
                <br />
                <b>Note:</b> The Name on the PAN card and the image proof
                uploaded as required for Bank Account verification should match
                each other. In addition to this, only the original image of the
                PAN card is acceptable, we do not accept photocopies of the PAN
                card for verification.
                <br />
              </p>
              <span className="content_box_green_heading">
                3. Why do I need a PAN card on Pickmonk?
              </span>
              <br />
              Pickmonk is an online fantasy sports gaming platform that enables
              its users to earn winnings by using their skills and knowledge. To
              make withdrawals from your Pickmonk account, it is mandatory to
              verify your PAN Card details with Pickmonk as proof of identity.
              Also, as per government rules, TDS deduction is necessary as &
              when required at Pickmonk, therefore, we require a user's PAN
              card.
              <br />
              In the event the user does not have a PAN Card, he/she can still
              play on Pickmonk and the winning amounts will be deposited in the
              "Your Winnings" section of the user's Pickmonk User Account.
              Further, the user can withdraw the amount by applying for a PAN
              card in the meantime and verifying his/her PAN card details with
              Pickmonk.
              <br /> <br />
              <span className="content_box_green_heading">
                4. Why did my verification fail?
              </span>
              <br />
              Your account verification with Pickmonk can fail due to multiple
              reasons such as
              <br />
              <p className="ms-4">
                1. You have not uploaded your PAN Card as an identity proof
                required for verification.
                <br />
                2. The PAN card number entered by you does not match the PAN
                card details submitted at the time of the verification request.
                <br />
                3. The Date of Birth entered by you at the time of creating your
                Pickmonk account does not match the Date of Birth mentioned on
                the PAN Card submitted by you.
                <br />
                4. Your name on the PAN card and the bank account does not
                match.
                <br />
                5. The IFSC code you have provided is either incorrect or does
                not belong to the bank branch mentioned for verification.
                <br />
                6. The visibility or clarity factor of the documents uploaded by
                you is very low.
                <br />
                7. The bank account number on the bank account proof uploaded by
                you for verification purposes does not match the Bank account
                number entered by you in the verification request.
                <br />
                8. In the event, that your verification request gets rejected
                and your verification fails you will be informed about the
                reason leading to the verification failure which will be
                appearing on top of the verification page for your reference.
              </p>
              <br />
              <br />
              <span className="content_box_green_heading">
                4. How do I withdraw winnings from my Pickmonk account?
              </span>
              <br />
              The users are allowed to withdraw winnings from the "My Winnings"
              section of their Pickmonk User Account, only after they complete
              the verification process. The users can withdraw a minimum of ₹100
              and a maximum of ₹1,00,000 in a day. Further, the withdrawal
              amount will be deposited to your bank accounts within 2-3 days.
              <br />
              <br />
              <span className="content_box_green_heading">
                5. What bank account details do I need to provide for
                withdrawal/verification?
              </span>
              To complete the withdrawal process, the user needs to provide
              certain information as a part of identity verification. The user
              needs to provide the following bank account details:
              <br />
              <p className="ms-4">
                1. Name of the bank <br />
                2. Name of the branch <br />
                3. Name of the account holder
                <br />
                4. Account Number
                <br />
                5. IFSC code
              </p>
              <br />
              <br />
              <h3> Legality and Fair Play:</h3>
              <br />
              <span className="content_box_green_heading">
                1. Is the paid version of Online Fantasy Sports Gaming legal in
                India?{" "}
              </span>
              <br />
              Yes, the paid version of Online Fantasy Sports Gaming Games is
              legal in India as it is considered to be a game of skill which is
              legal.
              <br /> <br />
              <span className="content_box_green_heading">
                2. Am I allowed to play if I am under the age of 18?
              </span>
              <br />
              Pickmonk does not allow any individual below the age of 18 to
              participate or use the services provided by Pickmonk.
              <br />
              <br />
              <span className="content_box_green_heading">
                3. Do I have to pay taxes when I withdraw winnings to my bank
                account?
              </span>
              <br />
              The winnings offered to the users declared as winners of the
              contest(s) organised by Pickmonk comply with a deduction of tax
              ("TDS") under the Income Tax Act 1961. In the event, the net
              winning amount of a user crosses the mark of ₹10,000, a 30%
              taxation charge will be imposed on the net winning of the user.
              <br />
              <br />
              <p className="ms-4">
                a. The net winning is more than ₹10,000, thus, a deduction of
                30% will be implied.
                <br />
                b. As credits are a promotional incentive provided to the users,
                they are exempted from TDS deduction.
                <br />
                c. The amount credited to the user's wallets will be the
                remaining amount after the TDS deduction.
                <br />
                d. In case of such tax deductions, the winners will be provided
                TDS certificates.
                <br />
                e. In compliance with section 269SU of the Income Tax Act, we
                insist to make payments by NEFT/RTGS/IMPS or any other E Modes.
                <br />
                f. The individuals declared as winners by Pickmonk agree to pay
                other applicable taxes, including (but not limited to) income
                tax, gift tax, etc. in respect of the winnings. The winnings or
                Credit Bonus are subjected to various taxes.
                <br />
                <br />
                <b>Note:</b> As Credit Bonuses are a promotional incentive
                provided to the users, they are exempted from TDS deduction.
              </p>
              <span className="content_box_green_heading">
                4. What is neither allowed nor appreciated by the Pickmonk
                platform?
              </span>
              <br />
              A single user cannot create multiple accounts on Pickmonk. Any
              user that misuses their referral code by creating different fake
              accounts on Pickmonk to earn Bonus. A user is not allowed to
              submit fake or some else's documents.
              <br />
              <br />
              <span className="content_box_green_heading">
                5. What activities on Pickmonk application are considered
                suspicious?
              </span>
              <br />
              Any suspected fraudulent or illegal activity to win the game may
              be subject to termination by Pickmonk. These activities include
              the use of any third-party applications or suspected hacking
              pursuit to manipulate the score or game on Pickmonk platform.
              These activities will lead to the termination of your account,
              which will require you to immediately cease using the services of
              Pickmonk.
              <br />
              <br />
              <span className="content_box_green_heading">
                6. Will Pickmonk take any action if my banking & PAN details are
                mismatched?
              </span>
              <br />
              Banking and PAN details provided by a user will be subjected to
              examination. Please note that the user will be blocked from the
              application if the banking and PAN details do not match, Pickmonk
              will send the notification to the user through the mail. A user
              will only be able to make withdrawals of the winnings once he/she
              sends a valid PAN and Banking details.            
            </p>
          </div>
          <div class="trapezoid_left "></div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Faq;
