export const APP_STORE_URL = 'https://apps.apple.com/in/app/pickmonk/id1561916915'
export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.pickmonk'
export const TAGS = {
    home: {
      title: "Best Fantasy Cricket App | Win With Just 11 Players | Pickmonk",
      description:
        "Get ready to win big with India's best fantasy cricket app. Play daily fantasy sports with just 11 players to win prizes. Download the fantasy app.",
      link: "https://pickmonk.com/"
    },
  
    "about-us": {
      title: "About Us | Pickmonk Fantasy Cricket App",
      description:
        "Learn more about Pickmonk. Step up to the crease and experience the thrill of fantasy cricket with us",
        link: "https://pickmonk.com/content/about-us"
    },
  
    "how-to-play": {
      title: "How To Play | Pickmonk Fantasy Cricket App",
      description:
        "Learn how to play and master the art of fantasy cricket. Check out the easy and simple guide on how pickmonk works",
      link:"https://pickmonk.com/content/how-to-play"
    },
  
    "contact-us": {
      title: "Contact Us | Pickmonk Fantasy Cricket App",
      description:
        "Join the excitement of fantasy cricket! Get in touch with us for any inquiries or assistance. Our dedicated support team is ready to help you win big",
        link:"https://pickmonk.com/content/contact-us"
    },
    fair_play: {
      title: "Fair Play | Pickmonk Fantasy Cricket App",
      description:
        "Pickmonk is committed to maintaining a fair and level playing field to preserve the competitiveness of the game",
      link: "https://pickmonk.com/content/fair_play"
    },
    "fair-play": {
      title: "Fair Play | Pickmonk Fantasy Cricket App",
      description:
        "Pickmonk is committed to maintaining a fair and level playing field to preserve the competitiveness of the game",
      link: "https://pickmonk.com/content/fair-play"
    },
    faqs: {
      title: "FAQ | Pickmonk Fantasy Cricket App",
      description:
        "Got questions about our fantasy sports platform? Check out our FAQ page for answers on how to play, win, and get the most out of your experience",
      link: "https://pickmonk.com/content/faqs"
    },
  };